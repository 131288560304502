import React from 'react';
import './PaginationFilters.scss';
import ReactPaginate from 'react-paginate';
import Select from '../select/Select';
import { useWindowSize } from '../../../utility/customHooks';

interface PaginationFiltersProps {
    onChange?: (args: any) => void;
    updateElements: (elements: number) => void;
    count?: number;
    itemPerPage: number;
    selected: number;
}

const PaginationFilters: React.FC<PaginationFiltersProps> = (props) => {

    const handlePageChange = (x: any) => {
        props.onChange!(x);
    };

    return (
        <div className='paginationFilters d-flex justify-content-between align-items-center'>
            <div className={"itemCounter w-100"}>{props.count || 0} items</div>
            {props.count || props.count === 0 ? <div className='d-flex w-100 justify-content-end'>
                <Select
                    customClass='me-2'
                    id="paginationCounter"
                    selectedValue={props.itemPerPage}
                    value={[1, 10, 15, 25, 50, 100]}
                    onChange={(str) => props.updateElements(Number(str))}
                />
                <ReactPaginate
                    forcePage={props.count !== 0 ? props.selected : undefined}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(props.count / props.itemPerPage)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={(selected: any) => {
                        let numPage = selected.selected;
                        handlePageChange(numPage);
                    }}
                    containerClassName={'pagination mb-0 pb-0 cursor-pointer'}
                    activeClassName={'active'}
                />
            </div> : ''}
        </div>
    );
}

export default PaginationFilters;
