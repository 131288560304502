import React, { useEffect, useState } from 'react';
import './MultiSelectSizes.scss';
import UserDataStorage from '../../../singletonStorage/userDataStorage';

interface MultiSelectProps {
    id: string;
    onChange: (arg: Array<any>) => void;
    disabled?: boolean;
    customClass?: string;
    placeholder?: string;
    value?: Record<string, Array<string>>;
    selectedValue?: Array<any>;
}

const MultiSelectSizes: React.FC<MultiSelectProps> = (props) => {

    const userData = UserDataStorage.getInstance();

    const [selectedOptions, setSelectedOptions] = useState<Array<any>>([]);
    const [tab, setTab] = useState('m');

    const handleOptionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: any) => {
        event.stopPropagation();
        let array: Array<string> = props.selectedValue ? [...props.selectedValue] : [];
        if (selectedOptions.includes(value)) {
            array = array.filter(option => option !== value);
        } else {
            array.push(value);
        }
        props.onChange(array);
        setSelectedOptions([...array]);
    };

    useEffect(() => {
        setSelectedOptions([...props.selectedValue || []]);
    }, [props.selectedValue])

    return (
        <>
            <div className={"multiSelectCommon " + props.customClass ?? ""}>
                <button
                    type="button"
                    className="buttonCommonMulti"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {props.placeholder}
                </button>
                <div>
                    <ul className="dropdown-menu dropdownMultiSelect overflow-hidden">
                    <div className='position-sticky' style={{top: 0}}>
                        <li key={'sizeTab'} className={'d-flex justify-content-center mb-1'} onClick={(e)=>e.stopPropagation()}>
                            <div className={'ps-2 pe-2 cursor-pointer text-center border-end border-white' + (tab === 'm' ? ' bg-dark' : '')} onClick={()=>setTab('m')}>
                                M
                            </div>
                            <div className={'ps-2 pe-2 cursor-pointer text-center border-end border-white' + (tab === 'w' ? ' bg-dark' : '')} onClick={()=>setTab('w')}>
                                W
                            </div>
                            <div className={'ps-2 pe-2 cursor-pointer text-center border-end border-white' + (tab === 'gs' ? ' bg-dark' : '')} onClick={()=>setTab('gs')}>
                                GS
                            </div>
                            <div className={'ps-2 pe-2 cursor-pointer text-center border-end border-white' + (tab === 'td' ? ' bg-dark' : '')} onClick={()=>setTab('td')}>
                                TD
                            </div>
                            <div className={'ps-2 pe-2 cursor-pointer text-center border-white' + (tab === 'other' ? ' bg-dark' : '')} onClick={()=>setTab('other')}>
                                OT
                            </div>
                        </li>
                    </div>
                    <div style={{height: 240, overflowY: 'auto'}}>
                        {
                            props.value && props.value[tab].map((e,i)=>{
                                return <li key={e[0] + i}>
                                    <div
                                    className={`color-white ps-2`}
                                    onClick={(event) => handleOptionClick(event, e + ' ' + userData.settingsStorage?.sizeCountry)}>
                                            <i className={`fa-regular ${selectedOptions.includes(e + ' ' + userData.settingsStorage?.sizeCountry) ? 'fa-check' : 'fa-square'} `}></i>
                                            <span className='ps-2'>{e + ' ' + (userData.settingsStorage?.sizeCountry !== "EU" ? userData.settingsStorage?.sizeCountry : '')}</span>
                                        </div>
                                    </li>
                                })
                            }
                            {
                                !props.value && <li key={'empty'}>
                                    <div
                                        className={`color-white ps-2`}
                                    >
                                        <i className={`fa-regular fa-xmark`}></i>
                                        <span className='ps-2'>Empty</span>
                                    </div>
                                </li>
                            }
                        </div>
                    </ul>
                </div>
            </div>
        </>
    );
}


export default MultiSelectSizes;
