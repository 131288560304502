import React, { useEffect, useState } from 'react';
import './MultiSelectFilters.scss';

interface MultiSelectFiltersProps {
    id: string;
    onChange: (arg: string) => void;
    disabled?: boolean;
    customClass?: string;
    placeholder?: string;
    value?: Array<{ label: string, lastSelect?: boolean, noArr?: boolean, value: boolean, name: string }>;
    selectedValue?: Array<any>;
}

const MultiSelectFilters: React.FC<MultiSelectFiltersProps> = (props) => {

    const handleOptionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: any) => {
        event.stopPropagation();
        props.onChange(value.name);
    };

    return (
        <>
            <div className={"multiSelectCommon " + props.customClass ?? ""}>
                <button
                    type="button"
                    className="buttonCommonMulti"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {props.placeholder}
                </button>
                <ul className="dropdown-menu dropdownMultiSelect">
                    {
                        props.value && props.value?.map((e, i) => {
                            return <li key={e.name}>
                                <div
                                    className={`d-flex cursor-pointer color-white`}
                                    onClick={(event) => handleOptionClick(event, e)}
                                >
                                    {!e.noArr && <i className={"ps-1 align-self-center fa-solid fa-caret-" + (e.value ? "up" : "down")}></i>}
                                    <span className={"ps-2 align-self-center" + (e.lastSelect ? " text-decoration-underline" : "")} style={{ width: "max-content", fontWeight: (e.lastSelect ? 800 : 400) }}>{e.label.toUpperCase()}</span>
                                </div>
                            </li>
                        })
                    }
                    {
                        !props.value && <li key={'empty'}>
                            <div
                                className={`color-white ps-2`}
                            >
                                <i className={`fa-regular fa-xmark`}></i>
                                <span className='ps-2'>Empty</span>
                            </div>
                        </li>
                    }
                </ul>
            </div>
        </>
    );
}


export default MultiSelectFilters;
