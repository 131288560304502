import httpClient from "../../utility/httpClient"
import { InventoryListInterface } from "../InventoryInterfaces";
import { FiltersInterface } from "../../utility/filtersUtils";
import { SellFilters as SellFiltersInterface } from "./SellFilterInterfaces";
import { PaginationFiltersInterface } from "../CommonInterfaces";

export default class SellService {

    MAX_ELEMENTS: number = 15;

    searchUrl = "/api/v1/wtb/";
    sellUrl = "/api/v1/sell/";

    public async getProducts(sortFilters?: FiltersInterface[], filters?: SellFiltersInterface, paginationFilter?: PaginationFiltersInterface): Promise<InventoryListInterface> {

        let orderBy = (sortFilters?.find(e => e.lastSelect)!.value ? "+" : "-") + (sortFilters?.find(e => e.lastSelect)!.name);

        let id = 'USERIDAUTH0'; //:invIdId
        let path = "/filterPublicWtb";
        let page = "?page=" + (paginationFilter?.page ? paginationFilter?.page + 1 : "1") + "&";
        let sizePage = "itemperpage=" + this.MAX_ELEMENTS;
        let body = {
            "Name": filters?.searchFilters || "",
            "PurchaseDate": [],
            "ShippingDate": [],
            "SaleDate": [],
            "Slug": "wtb",
            "Sizes": filters?.sizeFilter || [],
            "PurchaseStore": [],
            "ListedOn": [],
            "OrderBy": orderBy,
            "OnlySellNow": filters?.onlySellNow,
            "With": "wtb.item,wtb.variant,variant.size"
        };
        let url = this.searchUrl + id + path + page + sizePage;
        return httpClient.post(url, body).then((e: any) => {
            return e.data
        });
    }

    public async sellProduct(buyerId: string, wtbId: string): Promise<InventoryListInterface> {

        let id = 'USERIDAUTH0'; //:invIdId
        let path = "/sellNow";

        let body = {
            buyerId: buyerId,
            wtbId: wtbId,
        }
        let url = this.sellUrl + id + path;
        return httpClient.post(url, body).then((e: any) => {
            return e.data
        });
    }

}