import React from 'react';
import LoaderGif from '../../../assets/images/gif/loader.gif';
import './Loader.scss';

interface LoaderProps {
    customStyle?: any,
    bootstrapLoader?: boolean
}
const Loader: React.FC<LoaderProps> = (props) => {
    return (
      <div>
          {
              !props.bootstrapLoader && <img className="loader rounded-4" src={LoaderGif} alt={"Loading"} style={props.customStyle}/>
          }
          {
              props.bootstrapLoader &&
              <div className="spinner-border text-light font-weight-bold" role="status" style={props.customStyle}>
                  <span className="sr-only">Loading...</span>
              </div>
          }
      </div>
    );
}

export default Loader;