import React, { useRef } from 'react';
import './DatePicker.scss';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import moment from 'moment/moment';

interface DatePickerProps {
  id: string;
  onChange: (arg: Array<any>) => void;
  disabled?: boolean;
  customClass?: string;
  placeholder?: string;
  defaultValue?: any;
  enableMonthSelection?: boolean;
}

const DatePickerComponent: React.FC<DatePickerProps> = (props) => {
  const datePickerRef = useRef<any>(null);

  const handleOpenCalendar = () => {
    if (datePickerRef.current) {
      datePickerRef.current.openCalendar();
    }
  };

  const onDatePickerChange = (selectedDates: DateObject | DateObject[] | null) => {
    if (selectedDates != null) {
      let timestampDates = (selectedDates as DateObject[]).map((e) => {
        let date = e.format('DD-MM-YYYY');
        return moment.utc(date, 'DD-MM-YYYY', true).valueOf();
      });
      let strSelectedDates: string = selectedDates.toString();
      if (strSelectedDates.includes(',')) {
        strSelectedDates = strSelectedDates.replace(',', '-');
      }
      props.onChange(timestampDates);
    }
  };

  const enableMonth = () => {
    if (!props.enableMonthSelection) {
      return true;
    }
    const calendarDay = document.querySelector('.rmdp-day-picker');
    if (calendarDay) {
      calendarDay.setAttribute('style', 'display: flex; flex-direction: column;');
      const newDiv = document.createElement('div');
      newDiv.textContent = 'Select all month';
      newDiv.id = 'btnDatePickerMonth';
      newDiv.style.backgroundColor = 'var(--background-color-main)';
      newDiv.style.margin = '10px';
      newDiv.style.padding = '4px 2px';
      newDiv.style.borderRadius = '8px';
      newDiv.style.cursor = 'pointer';
      newDiv.onclick = selectMonth;
      if (!document.querySelector('#btnDatePickerMonth')) {
        calendarDay.appendChild(newDiv);
      }
    }
  };

  const selectMonth = () => {
    const displayedDate = document.querySelector('.rmdp-header-values')?.getElementsByTagName('span');

    if (displayedDate) {
      const monthName = displayedDate[0].textContent!;
      const year = displayedDate[1].textContent!;

      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const selectedMonth = months.indexOf(monthName);
      const selectedYear = parseInt(year, 10);

      const startOfMonth = new Date(selectedYear, selectedMonth, 1);
      const endOfMonth = new Date(selectedYear, selectedMonth + 1, 0);

      const startDateObject = new DateObject(startOfMonth);
      const endDateObject = new DateObject(endOfMonth);

      // Seleziona le date nel DatePicker
      //setValues([startDateObject, endDateObject]);
      props.onChange([startDateObject, endDateObject]);
    }
  };

  return (
    <>
      <div className={`datePickerContainer ${props.customClass || ''}`}>
        <div className="icon-date-picker" onClick={handleOpenCalendar}>
          <i className="fa-regular fa-calendar"></i>
        </div>
        <DatePicker
          onOpen={() => {
            setTimeout(() => enableMonth(), 100);
            return true;
          }}
          ref={datePickerRef}
          inputClass={`datePickerInput`}
          className="datePickerCalendar"
          arrowClassName="bg-dark"
          range
          fixMainPosition
          placeholder={`${props.placeholder || ''}`}
          value={props.defaultValue}
          onChange={(selectedDates) => onDatePickerChange(selectedDates)}
        />
      </div>
    </>
  );
};

export default DatePickerComponent;
