import { match } from 'path-to-regexp';

export interface Navigator {
  name: string;
  link: string;
  fa_icon?: string;
  disabled?: boolean;
  hide?: boolean;
  needLogin?: boolean;
  roleGuard?: string[];
}

export default class SideBarNavigator {
  navigation: Array<Navigator> = [
    {
      name: 'Dashboard',
      link: '/dashboard',
      fa_icon: 'fa-solid fa-chart-pie',
      needLogin: true,
    },
    {
      name: 'Inventory',
      link: '/inventory',
      fa_icon: 'fa-solid fa-clipboard-list',
      needLogin: true,
    },
    {
      name: 'Admin Inventory',
      link: '/admin_Inventory',
      fa_icon: 'fa-solid fa-users',
      needLogin: true,
      roleGuard: ['!c3RlcGFkbWlu!'],
    },
    /*       {
                   name: 'Listed',
                   link: '/listed',
                   fa_icon: 'fa-sharp fa-solid fa-list-ul',
                   disabled: true
               },*/
    {
      name: 'Shipping',
      link: '/shipping',
      fa_icon: 'fa-solid fa-dolly',
      needLogin: true,
    },
    {
      name: 'Sold',
      link: '/sold',
      fa_icon: 'fa-solid fa-money-bill-wave',
      needLogin: true,
    },
    {
      name: 'Wtbs',
      link: '/wtbs',
      fa_icon: 'fa-solid fa-solid fa-store',
      needLogin: true,
    },
    {
      name: 'Sell',
      link: '/sell',
      fa_icon: 'fa-solid fa-money-bill-trend-up',
      needLogin: true,
    },
    {
      name: 'Incomes/Expenses',
      link: '/incomes-expenses',
      fa_icon: 'fa-solid fa-scale-unbalanced',
      needLogin: true,
    },
    {
      name: 'Settings',
      link: '/settings',
      fa_icon: 'fa-solid fa-gear',
      needLogin: true,
    },
    {
      name: 'Debug',
      link: '/debug',
      hide: true,
      needLogin: false,
    },
    {
      name: 'Shared User Inventory',
      link: '/shared-inventory/:id/:items',
      fa_icon: 'fa-solid fa-clipboard-list',
      needLogin: false,
      hide: true,
    },
  ];

  public get getNavigation(): Array<Navigator> {
    return this.navigation;
  }

  getNameByUrl(url: string): string | undefined {
    return this.getByUrl(url)?.name;
  }

  getByUrl(url: string): Navigator | undefined {
    return this.navigation.find((route) => {
      try {
        const matcher = match(route.link, { decode: decodeURIComponent });
        return matcher(url) !== false;
      } catch (error) {
        console.error('Error matching URL with route pattern:', error);
        return undefined;
      }
    });
  }
}
