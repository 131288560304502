import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../CommonModal.scss';
import './ViewOrEditItemModal.scss';
import Button from '../../common/button/Button';
import Logo from '../../../assets/images/logo/transparent-logo.png';
import { InventoryResultInterface } from '../../../services/InventoryInterfaces';
import PurchaseSection from './sections/purchase/PurchaseSection';
import ListingSection from './sections/listings/ListingsSection';
import SaleSection from './sections/sale/SaleSection';
import ShippingSection from './sections/shipping/ShippingSection';
import moment from 'moment';
import InventoryListService from '../../../services/inventory/InventoryListService';
import ToastHandler from '../../../utility/ToastHandler/ToastHandler';
import { hasNonEmptyStringProperty } from '../../../utility/commonUtils';
const _ = require('lodash');

interface ViewOrEditItemModalModalProps {
  show: boolean;
  onHide: (didChange?: boolean) => void;
  item: InventoryResultInterface;
  type: string;
  disableEdit: boolean;
  onlyInfo?: boolean;
}

export const schemaErrorsViewOrEditModal = {
  purchase: {
    purchaseDate: '',
  },
  listings: {},
  shipping: {},
  sale: {
    saleDate: '',
  },
};

const ViewOrEditItemModal: React.FC<ViewOrEditItemModalModalProps> = (props) => {
  //Service
  const inventoryService = new InventoryListService();

  const [locked, setLocked] = useState<boolean>(true);

  const [item, setItem] = useState<Record<any, any>>();
  const [type, setType] = useState<string>(props.type);
  const [pendingChanges, setPendingChanges] = useState<boolean>(false);
  const [showWarningClose, setShowWarningClose] = useState<boolean>(false);

  const [errors, setErrors] = useState(schemaErrorsViewOrEditModal);

  const saveChanges = async () => {
    if (!item) {
      props.onHide();
      return;
    }
    if (
      hasNonEmptyStringProperty(errors.purchase) ||
      hasNonEmptyStringProperty(errors.shipping) ||
      hasNonEmptyStringProperty(errors.listings) ||
      hasNonEmptyStringProperty(errors.sale)
    ) {
      return;
    }
    await inventoryService.updatePurchase(props.item.Id, item).then((e) => {
      ToastHandler.customToast({ message: 'Purchase Updated' });
      props.onHide(true);
    });
  };

  const setChanges = (fields: Record<any, any>) => {
    if (fields && fields['purchaseDate']) {
      let date = fields['purchaseDate'];
      const validDate = date ? moment(date, 'DD-MM-YYYY', true).isValid() : true;
      if (!validDate || date === '') {
        if (!validDate && date?.length > 9) {
          setErrors((prev) => ({ ...prev, purchase: { ...prev.purchase, purchaseDate: 'Missing or Incorrect Date, insert correct format: DD-MM-YYYY' } }));
        }
      } else {
        setErrors((prev) => ({ ...prev, purchase: { ...prev.purchase, purchaseDate: '' } }));
      }
      fields['purchaseDate'] = moment(date, 'DD-MM-YYYY', true).toDate().getTime();
    }
    if (fields && fields['sale'] && fields['sale']['saleDate']) {
      let date = fields['sale']['saleDate'];
      const validDate = date ? moment(date, 'DD-MM-YYYY', true).isValid() : true;
      if (!validDate || date === '') {
        if (!validDate && date?.length > 9) {
          setErrors((prev) => ({ ...prev, sale: { ...prev.sale, saleDate: 'Missing or Incorrect Date, insert correct format: DD-MM-YYYY' } }));
        }
      } else {
        setErrors((prev) => ({ ...prev, sale: { ...prev.sale, saleDate: '' } }));
      }
      fields['sale']['saleDate'] = moment(date, 'DD-MM-YYYY', true).toDate().getTime();
    }
    if (fields && fields['purchasePrice']) {
      fields['purchasePrice'] = Number(Number(fields['sale']['salePrice']?.replaceAll(',', '.')).toFixed(2));
    }
    if (fields && fields['sale'] && fields['sale']['salePrice']) {
      fields['sale']['salePrice'] = Number(Number(fields['sale']['salePrice']?.replaceAll(',', '.')).toFixed(2));
    }

    setItem((item: any) => {
      if (item) {
        setPendingChanges(true);
        return { ..._.merge(item, fields) };
      } else {
        return { ..._.merge(fields) };
      }
    });
  };

  const handleClose = (forceClose?: boolean) => {
    if (forceClose) {
      setPendingChanges(false);
      setShowWarningClose(false);
      props.onHide();
      return;
    }
    if (pendingChanges) {
      setShowWarningClose(true);
      return;
    }
    props.onHide();
  };

  const shouldDisableSell = () => {
    return props.type === 'purchase' || props.item?.sale === null;
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered contentClassName={'commonModal'} backdropClassName={'commonModalBackdrop'}>
      <Modal.Body>
        <>
          <>
            {showWarningClose && (
              <div className={'closeConfirm'}>
                <div className={'w-100 h-100 p-1 color-white d-flex flex-column justify-content-center align-items-center'}>
                  <div className={'w-100 p-2 mb-2 text-center message'}>You have pending changes, are you sure you want to close?</div>
                  <div className={'d-flex justify-content-center align-items-center'}>
                    <Button customClass={'closeConfirmButtons btn-dark'} id={'discard'} onClick={() => setShowWarningClose(false)} value={'Discard'} />
                    <Button customClass={'closeConfirmButtons bg-danger btn-danger'} id={'close'} onClick={() => handleClose(true)} value={'Close'} />
                  </div>
                </div>
              </div>
            )}
            <div className={`viewOrEditItemModal ${showWarningClose ? 'blurred' : ''}`}>
              <div className={'containerModal'}>
                <div className={'closeButton'} onClick={() => handleClose()}>
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className={'mt-2 ps-3 pe-3 pt-4 pb-2 color-white'}>
                  <div className="productDetailView">
                    <img
                      className="p-1 rounded-3"
                      src={props.item.item.imgUrl}
                      onError={({ currentTarget }) => {
                        try {
                          currentTarget.onerror = null;
                          currentTarget.src = Logo;
                        } catch (e) {}
                      }}
                      width={80}
                      height={80}
                      alt={'jordan'}
                    />
                    <div className={'w-100 d-flex justify-content-between'}>
                      <div className={'text-right ps-1 pe-1'}>
                        <div className={'brand'}>{props.item.item.brandId}</div>
                        <div className={'name'}>{props.item.item.name}</div>
                        <div className={'sku'}>{props.item.item.sku}</div>
                      </div>
                      <div className={'d-flex ps-1 pe-1 align-items-center'}>
                        <div className={'size pe-2'}>{props.item.variant.size.EU} EU</div>
                        <div className={'ps-2 pe-2'}>
                          <div className={'sizeMini'}>{props.item.variant.size.US} US</div>
                          <div className={'sizeMini'}>{props.item.variant.size.UK} UK</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!props.onlyInfo && (
                    <div>
                      <div className={'mt-2 row typeModal g-0'}>
                        <div
                          className={`${type === 'purchase' ? 'selected' : ''} col-6 col-sm-3 ${hasNonEmptyStringProperty(errors?.purchase) ? 'bg-danger' : ''}`}
                          onClick={() => setType('purchase')}>
                          Purchase
                        </div>
                        {/* <div className={`${type === "listings" ? "selected" : ""} col-3 ${hasNonEmptyStringProperty(errors?.listings) ? "bg-danger" : ""}`} onClick={()=>setType("listings")}> */}
                        <div
                          className={`disabled cursor-not-allowed col-6 col-sm-3 ${hasNonEmptyStringProperty(errors?.listings) ? 'bg-danger' : ''}`}
                          onClick={() => {
                            //setType("listings");
                          }}>
                          Listings
                        </div>
                        <div
                          className={`${type === 'shipping' ? 'selected' : ''} col-6 col-sm-3 ${hasNonEmptyStringProperty(errors?.shipping) ? 'bg-danger' : ''}`}
                          onClick={() => setType('shipping')}>
                          Shipping
                        </div>
                        <div
                          className={`${shouldDisableSell() ? 'disabled' : type === 'sale' ? 'selected' : ''} col-6 col-sm-3 ${hasNonEmptyStringProperty(errors?.sale) ? 'bg-danger' : ''}`}
                          onClick={() => props.type !== 'purchase' && setType('sale')}>
                          Sale
                        </div>
                      </div>
                      <div className={'mt-2'}>
                        {type === 'purchase' && (
                          <PurchaseSection item={props.item} locked={locked} error={errors.purchase} onChange={(args) => setChanges(args)} />
                        )}
                        {type === 'listings' && (
                          <ListingSection item={props.item} locked={locked} error={errors.listings} onChange={(args) => setChanges(args)} />
                        )}
                        {type === 'shipping' && (
                          <ShippingSection item={props.item} locked={locked} error={errors.shipping} onChange={(args) => setChanges(args)} />
                        )}
                        {type === 'sale' && <SaleSection item={props.item} locked={locked} error={errors.sale} onChange={(args) => setChanges(args)} />}
                      </div>
                      {!props.disableEdit && (
                        <div className={'w-100 d-flex justify-content-end align-items-center mt-3'}>
                          {!locked && <Button id={'viewModalSave'} onClick={() => saveChanges()} value={'Save'} customClass={'me-2'} />}
                          <div
                            className={'h4 mt-2 pe-1 ps-1 cursor-pointer' + (!locked ? ' text-secondary' : ' text-white')}
                            style={{ transform: 'scaleY(1.1)' }}
                            onClick={() => setLocked(!locked)}>
                            {!locked ? <i className="fa-solid fa-lock-open" /> : <i className="fa-solid fa-lock"></i>}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ViewOrEditItemModal;
