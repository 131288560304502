import React from 'react';
import './Select.scss';

interface SelectProps {
    id: string;
    onChange: (arg: string) => void;
    disabled?: boolean;
    customClass?: string;
    placeholder?: string;
    value?: Array<any>;
    selectedValue?: any;
    error?: string;
}

const Select: React.FC<SelectProps> = (props) => {

    return (
        <>
            <select
                    key={`${Math.floor((Math.random() * 1000))}-min`}
                    disabled={props.disabled}
                    className={`${props.customClass || ""} btn selectCommon ${props.error ? "border-danger" : ""}`}
                    id={props.id}
                    value={props.selectedValue || ""}
                    placeholder={props.placeholder}
                    onChange={(event) => props.onChange(event.target.value)}
            >
                <option value="" disabled>{props.placeholder}</option>
                {props.value ? props.value.map((e,i)=>{
                    return <option key={e + i} value={e}>{e}</option>
                }) :  <option disabled key={'not'}>Empty</option>}
            </select>
            {props.error && <span className={"text-danger inputError"}>{props.error}</span>}
        </>
    );
}


export default Select;
