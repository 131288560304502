import cronstrue from 'cronstrue';
import UserDataStorage from '../singletonStorage/userDataStorage';

function getDateByTimeStamp(timestamp: number, hyphens?: boolean): string {
  if (!timestamp) {
    return '';
  }
  let options: any = { day: '2-digit', month: '2-digit', year: 'numeric' };
  let date: string = new Date(timestamp).toLocaleDateString(undefined, options);
  if (hyphens) {
    date = date.split('/').join('-');
  }
  return date;
}

function formatPrice(price?: string | number, currency?: string, showCur?: boolean) {
  if (!price) return '0' + (showCur ? ' ' + getCurrencySymbol(currency) : '');
  price = typeof price === 'number' ? price.toString() : price;
  const value = parseFloat(price.replace(',', '.'));
  if (value % 1 === 0) {
    price = value.toFixed(2);
  } else {
    price = value.toFixed(2).replace('.', ',');
  }
  return price.replace('.', ',') + (showCur ? ' ' + getCurrencySymbol(currency) : '');
}

function hasNonEmptyStringProperty(obj: any) {
  for (let key in obj) {
    if (typeof obj[key] === 'string' && obj[key].trim() !== '') {
      return true;
    }
  }
  return false;
}

function formatNumber(number: number | string): string {
  let numVal = number;
  let numString = '';
  if (typeof number === 'string') {
    numVal = Number(number.split(' ')[0]);
    numString = number.split(' ')[1];
  }
  try {
    const numFixed = (numVal as number).toFixed(2);
    const [unit, decimal] = numFixed.split('.');
    const unitFormat = unit.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${unitFormat},${decimal} ${numString}`;
  } catch (err) {
    return number.toString();
  }
}

function getCurrentTimeHHMM(): string {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

function formatCron(cronTime: string): string {
  let str = '';
  str = cronstrue.toString(cronTime, { verbose: true });
  if (str.toLowerCase().includes('at 12:00 am')) {
    str = str
      .replace('At 12:00 AM', '')
      .replace(', ', '')
      .replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
    return str;
  }
  return '';
}

function monthsToDays(months: number) {
  let days = 0;
  for (let month = 1; month <= months; month++) {
    days += new Date(new Date().getFullYear(), month, 0).getDate();
  }
  return days;
}

function removeDuplicatesFromArray(array: Array<any>) {
  return array.filter(function (item, index, inputArray) {
    return inputArray.indexOf(item) === index;
  });
}

function checkIfArrayAreEquals(array1: any[], array2: any[]): boolean {
  const bool =
    array1.length === array2.length && array1.every((element) => array1.filter((e) => e === element).length === array2.filter((e) => e === element).length);
  return bool;
}

function makeUpperCase(str: string): string {
  return str[0].toUpperCase() + str.slice(1);
}

function getCurrencySymbol(currency?: string) {
  if (!currency) {
    return UserDataStorage.getInstance().settingsStorage!['currency'];
  }
  switch (currency) {
    case 'AED':
      return 'د.إ';
    case 'AUD':
      return 'A$';
    case 'EUR':
      return '€';
    case 'TWD':
      return 'NT$';
    case 'CHF':
      return 'CHF';
    case 'CNY':
      return '¥';
    case 'GBP':
      return '£';
    case 'HKD':
      return 'HK$';
    case 'HRK':
      return 'kn';
    case 'NZD':
      return 'NZ$';
    case 'PLN':
      return 'zł';
    case 'USD':
      return '$';
    case 'CAD':
      return 'C$';
    case 'CNH':
      return 'CNH';
    case 'JPY':
      return '¥';
    case 'RUB':
      return '₽';
    case 'SGD':
      return 'S$';
    case 'TRY':
      return '₺';
    default:
      return '';
  }
}

function isAdmin() {
  const userData = UserDataStorage.getInstance();
  return userData.sessionStorage?.roles?.some((_e: string) => {
    return _e === '!c3RlcGFkbWlu!';
  });
}

const copyTextToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error('Failed to copy!', error);
    }
  } else {
    fallbackCopyTextToClipboard(text); // Fallback for older browsers
  }
};

// Fallback for older browsers
const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export {
  getDateByTimeStamp,
  formatPrice,
  hasNonEmptyStringProperty,
  formatNumber,
  getCurrentTimeHHMM,
  formatCron,
  monthsToDays,
  makeUpperCase,
  removeDuplicatesFromArray,
  checkIfArrayAreEquals,
  getCurrencySymbol,
  isAdmin,
  copyTextToClipboard,
};
