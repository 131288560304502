import React, { useCallback, useEffect, useMemo, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import './AdminInventory.scss';
import Filters from "../../../common/filters/Filters";
import RowFilters from "../../../common/rowFilters/rowFilters";
import FiltersUtils, { FiltersInterface as SortFiltersInterface } from "../../../../utility/filtersUtils";
import RowItem from "../../../common/rowItem/rowItem";
import AddModal_Inventory from "../../../modals/AddModal_Inventory/AddModal_Inventory";
import InventoryListService from "../../../../services/inventory/InventoryListService";
import { InventoryListInterface, InventoryResultInterface } from "../../../../services/InventoryInterfaces";
import Loader from "../../../common/loader/Loader";
import { InventoryFilters } from '../../../../services/inventory/InventoryFilterInterfaces';
import { PaginationFiltersInterface } from '../../../../services/CommonInterfaces';
import PaginationFilters from '../../../common/paginationFilters/PaginationFilters';
import { ExpenseIncome } from "../../../../services/expenseincome/ExpenseIncomeInterface";
import { checkIfArrayAreEquals } from "../../../../utility/commonUtils";
import WarningModal from '../../../modals/WarningModal/WarningModal';
import LazyImage from "../../../common/lazyimage/LazyImage";
import Checkbox from "../../../common/checkbox/Checkbox";
import AdminInventoryListService from "../../../../services/admin_inventory/AdminInventoryListService";


const AdminInventory: React.FC = () => {

    //Service
    const adminInventoryService = useMemo(() => {
        let service = new AdminInventoryListService();
        service.MAX_ELEMENTS = FiltersUtils.initializeItemPerPage();
        return service;
    }, []);

    const [MAX_ELEMENTS, setMaxElements] = useState<number>(FiltersUtils.initializeItemPerPage());
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<InventoryFilters>();
    const [sortFilters, setSortFilters] = useState<Array<SortFiltersInterface>>(FiltersUtils.initializeFilters('adminInventory'));
    const [paginationFilters, setPagination] = useState<PaginationFiltersInterface>({ page: 0, count: undefined });
    const [list, setList] = useState<InventoryListInterface>();
    const [listItems, setListItems] = useState<Array<InventoryResultInterface>>([]);

    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    const [selectedTasks, setSelectedTasks] = useState<Array<string>>([]);

    const getList = useCallback(async (sortFilters?: SortFiltersInterface[], filters?: InventoryFilters, paginationFilters?: PaginationFiltersInterface) => {
        setIsLoading(true);
        return await adminInventoryService.getProducts(sortFilters, filters, paginationFilters).then((_list) => {
            setSelectedTasks([...[]]);
            setList(_list);
            setPagination((prev) => { return { ...prev, count: _list.itemsCount } });
            setListItems(_list.results ? [..._list.results] : []);
            setIsLoading(false);
        });
    }, [adminInventoryService]);

    useEffect(() => {
        getList(sortFilters, filters, paginationFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getList, sortFilters, filters, paginationFilters.page]);

    useEffect(() => {
        setPagination((prev) => { return { ...prev, page: 0 } });
    }, [filters]);

    const refreshList = useCallback(async () => {
        await getList(sortFilters, filters, paginationFilters);
    }, [filters, getList, paginationFilters, sortFilters])

    const handleFilters = (filter: any) => {
        if (filter) {
            setFilters(filter);
            return;
        }
        setFilters(undefined);
    }

    const handlePaginationFilter = (pagFilter: number) => {
        if (pagFilter !== undefined) {
            setPagination((prev) => { return { ...prev, page: pagFilter } });
            return;
        }
    }

    const handleSortFilter = (filter?: string) => {
        if (!filter) {
            setSortFilters([...FiltersUtils.initializeFilters('adminInventory')]);
            return;
        }
        if (sortFilters) {
            let filterToUpdate = sortFilters.find(e => e.name === filter);
            if (filterToUpdate) {
                (filterToUpdate.value = !filterToUpdate!.value);
                (filterToUpdate.lastSelect = true);
                sortFilters.forEach(e => {
                    if (e.name !== filterToUpdate!.name) {
                        e.value = false;
                        e.lastSelect = false;
                    }
                });
                localStorage.setItem('filtersAdminInventory', JSON.stringify(sortFilters));
                setSortFilters([...sortFilters]);
            }
            return;
        }
        setSortFilters([]);
    }

    const openModal = (bool?: boolean) => {
        setShowAddModal(bool || !showAddModal);
    }

    const setItemPerPage = (pages: number) => {
        adminInventoryService.MAX_ELEMENTS = pages;
        localStorage.setItem('itemPerPage', pages.toString());
        setMaxElements(pages);
        setPagination((prev) => { return { ...prev, page: 0 } });
        refreshList();
    }

    return (
        <>
            <div className='inventory'>
                <Filters type={'adminInventory'} openModal={() => { }} onChange={(args) => { handleFilters(args) }} showDeleteSelectedTasks={selectedTasks.length > 0} deleteSelectedTasks={() => { }} />
                <RowFilters type="adminInventory" filters={sortFilters} selected={listItems?.length > 0 ? checkIfArrayAreEquals(selectedTasks, listItems.map(e => e.Id)) : false} handleCheckbox={() => { }} handleFilterClick={(str) => handleSortFilter(str)} />
                {isLoading && <div className='inventoryContainer d-flex justify-content-center align-items-center w-100'>
                    {<Loader bootstrapLoader customStyle={{ width: '36px', height: '36px', marginTop: '50px' }} />}
                </div>}
                {!isLoading && listItems?.length > 0 && <div className='inventoryList'>
                    {listItems.map((v, i) =>
                        <RowItem selected={selectedTasks.includes(v.Id)} onSelect={() => { }} refresh={() => refreshList()} key={i} filters={sortFilters} type="adminInventory" item={v} />)
                    }
                </div>}
                {
                    !isLoading && !list?.results && <div className='inventoryContainer d-flex justify-content-center align-items-center w-100'>
                        {
                            <span className="color-white font-weight-bold listMessage"
                                onClick={() => openModal()}>
                                Inventory is clear
                            </span>
                        }
                    </div>
                }
                {!isLoading && <div className='divider' />}
                {!isLoading && <PaginationFilters count={paginationFilters.count} selected={paginationFilters.page!} itemPerPage={MAX_ELEMENTS} updateElements={(pages) => { setItemPerPage(pages) }} onChange={(res) => { handlePaginationFilter(res) }} />}
            </div>
        </>
    );
}


export default AdminInventory;