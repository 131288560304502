import React, { useState } from 'react';
import Loader from '../loader/Loader';
import ImageModal from '../../modals/ImageModal/ImageModal';
import Logo from './../../../assets/images/logo/transparent-logo.png';
import { useWindowSize } from '../../../utility/customHooks';

interface LazyImageProps {
  src?: any;
  alt?: string;
  customClass?: string;
}

const LazyImage: React.FC<LazyImageProps> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [width] = useWindowSize();

  const openImageModal = () => {
    setShow(true);
  }

  return (
    <div>
      {loaded ? null : (
        <div
          className={`${props.customClass || ""}`}
        >
          <Loader bootstrapLoader
            customStyle={{ width: width > 768 ? 26 : 36, height: width > 768 ? 26 : 36, padding: '6px', borderWidth: '3px', marginTop: '8px' }} />
        </div>
      )}
      <img
        style={loaded ? {} : { display: 'none' }}
        src={props.src || Logo}
        className={`${props.customClass || ""} cursor-pointer`}
        alt={props.alt || 'Placeholder'}
        onLoad={() => setLoaded(true)}
        onClick={() => openImageModal()}
      />

      <ImageModal show={show} onHide={() => setShow(false)} children={
        <img
          src={props.src}
          className={`w-100 h-100 cursor-pointer`}
          alt={props.alt || 'Big Image on Modal'}
          onLoad={() => setLoaded(true)}
          onClick={() => openImageModal()}
        />
      } />

    </div>
  );
};

export default LazyImage;