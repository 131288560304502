import UserDataStorage from '../singletonStorage/userDataStorage';

function roleCheck(role: string): boolean {
  try {
    const sessionStorage = UserDataStorage.getInstance().sessionStorage!;

    if (sessionStorage.roles && sessionStorage.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

function typeCheck(type: string): boolean {
  try {
    const settingsStorage = UserDataStorage.getInstance().settingsStorage!;

    if (type === 'nation') {
      return settingsStorage.nation && settingsStorage.nation !== '';
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

export { roleCheck, typeCheck };
