import React, { useEffect, useState } from 'react';
import './ShippingSection.scss';
import { InventoryResultInterface } from '../../../../../services/InventoryInterfaces';
import Input from '../../../../common/input/Input';
import { schemaErrorsViewOrEditModal } from '../../ViewOrEditItemModal';
import Select from '../../../../common/select/Select';
import UserDataStorage from '../../../../../singletonStorage/userDataStorage';
import ToastHandler from '../../../../../utility/ToastHandler/ToastHandler';
const _ = require('lodash');

interface ListingSectionProps {
  item?: InventoryResultInterface | any;
  locked: boolean;
  onChange: (args: Record<any, any>) => void;
  error?: (typeof schemaErrorsViewOrEditModal)['shipping'];
}

const ShippingSection: React.FC<ListingSectionProps> = (props) => {
  const userData = UserDataStorage.getInstance();

  const [item, setItem] = useState<any>(props.item?.sale?.ship || {});

  const updateItem = (fieldToUpdate: any) => {
    setItem((item: any) => {
      if (item) {
        let obj = { ..._.merge(item, fieldToUpdate) };
        props.onChange({ ...{ sale: { ...{ ship: obj } } } });
        return obj;
      } else {
        let obj = { ..._.merge(fieldToUpdate) };
        props.onChange({ ...{ sale: { ...{ ship: obj } } } });
        return obj;
      }
    });
  };

  const openTrackingLink = () => {
    if (props?.item?.sale?.ship?.trackingUrl) {
      setTimeout(() => {
        window.open(props?.item?.sale?.ship?.trackingUrl);
      });
    } else {
      ToastHandler.customToast({
        message: 'Cannot open url, retry later or go to the courier website',
      });
    }
  };

  return (
    <>
      <div>
        {props.item && (
          <>
            <div className={'mt-3 pt-3'}>
              <div className={'row'}>
                <div className={'col-12 col-sm-3 d-flex flex-column'}>
                  <label className={'labelModal'} htmlFor={'courierModal'}>
                    Courier
                  </label>
                  <Select
                    id="courierModal"
                    selectedValue={item?.courierId || 'Auto'}
                    value={['Auto', ...userData.sessionStorage!['couriers']]}
                    placeholder="Courier"
                    onChange={(str) => updateItem({ courierId: str })}
                    disabled={props.locked}
                  />
                </div>
                <div className={'col-12 col-sm-5'}>
                  <label className={'labelModal'} htmlFor={'courierModal'}>
                    Tracking
                  </label>
                  <Input
                    id={'trackingModal'}
                    placeholder={''}
                    defaultValue={item?.trakingCode || ''}
                    onChange={(str) => updateItem({ trakingCode: str })}
                    disabled={props.locked}
                    icon={'fa-solid fa-truck-fast'}
                    iconClick={() => openTrackingLink()}
                  />
                </div>
                <div className={'col-12 col-sm-4'}>
                  <label className={'labelModal'} htmlFor={'courierModal'}>
                    Status
                  </label>
                  <Input
                    id={'statusModal'}
                    placeholder={''}
                    defaultValue={item?.status || ''}
                    onChange={(str) => updateItem({ status: str })}
                    disabled={true}
                    customClass={
                      !item?.status
                        ? item?.status?.toLowerCase() === 'delivered' || item?.status?.toLowerCase() === 'not found'
                          ? item?.status?.toLowerCase() === 'delivered'
                            ? 'itemGreen'
                            : 'itemRed'
                          : 'itemYellow'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {!props.item && (
          <>
            <div className={'mt-4 pt-4 mb-4 pb-4 color-white text-center'}>
              <div className={'mt-4 pt-4 mb-2 pb-2'}>
                <i className="fa-solid fa-face-dizzy h1"></i>
              </div>
              <div>Uh Oh! No Shipping Found for this item</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ShippingSection;
