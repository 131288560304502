import httpClient from '../../utility/httpClient';
import { InventoryListInterface } from '../InventoryInterfaces';
import { FiltersInterface } from '../../utility/filtersUtils';
import { InventoryFilters as InventoryFiltersInterface } from './InventoryFilterInterfaces';
import { PaginationFiltersInterface } from '../CommonInterfaces';

export default class InventoryListService {
  MAX_ELEMENTS: number = 15;

  searchUrl = '/api/v1/inventory/';
  searchOpenUrl = '/openapi/sharedinv/';

  public async getProducts(
    sortFilters?: FiltersInterface[],
    filters?: InventoryFiltersInterface,
    paginationFilter?: PaginationFiltersInterface
  ): Promise<InventoryListInterface> {
    let orderBy = (sortFilters?.find((e) => e.lastSelect)!.value ? '+' : '-') + sortFilters?.find((e) => e.lastSelect)!.name;

    let id = 'USERIDAUTH0'; //:invIdId
    let path = '/filterPurchases';
    let page = '?page=' + (paginationFilter?.page ? paginationFilter?.page + 1 : '1') + '&';
    let sizePage = 'itemperpage=' + this.MAX_ELEMENTS;
    let body = {
      Name: filters?.searchFilters || '',
      PurchaseDate: filters?.purDateFilter || [],
      ShippingDate: [],
      SaleDate: [],
      Slug: 'inventory',
      Sizes: filters?.sizeFilter || [],
      PurchaseStore: filters?.purStoreFilter || [],
      ListedOn: [],
      OrderBy: orderBy,
      SkipDuplicates: filters?.hideDuplicates,
      With: 'purchase.item,purchase.variant,variant.size,purchase.listings,purchase.sale',
    };
    let url = this.searchUrl + id + path + page + sizePage;
    return httpClient.post(url, body).then((e: any) => {
      return e.data;
    });
  }

  public async getSharedProducts(
    pathRoute?: string,
    sortFilters?: FiltersInterface[],
    filters?: InventoryFiltersInterface,
    paginationFilter?: PaginationFiltersInterface
  ): Promise<InventoryListInterface> {
    let orderBy = (sortFilters?.find((e) => e.lastSelect)!.value ? '+' : '-') + sortFilters?.find((e) => e.lastSelect)!.name;

    const parsedPath = pathRoute?.split('/')!;

    let pathParams = parsedPath[2] + '/' + parsedPath[3];
    let path = '/filterPurchases';
    let page = '?page=' + (paginationFilter?.page ? paginationFilter?.page + 1 : '1') + '&';
    let sizePage = 'itemperpage=' + this.MAX_ELEMENTS;
    let body = {
      Name: filters?.searchFilters || '',
      PurchaseDate: filters?.purDateFilter || [],
      ShippingDate: [],
      SaleDate: [],
      Slug: 'inventory',
      Sizes: filters?.sizeFilter || [],
      PurchaseStore: filters?.purStoreFilter || [],
      ListedOn: [],
      OrderBy: orderBy,
      SkipDuplicates: filters?.hideDuplicates,
      With: 'purchase.item,purchase.variant,variant.size,purchase.listings,purchase.sale',
    };
    let url = this.searchOpenUrl + pathParams + path + page + sizePage;
    return httpClient.post(url, body).then((e: any) => {
      return e.data;
    });
  }

  public async getSharedProductsSession(pathRoute?: string): Promise<any> {
    const parsedPath = pathRoute?.split('/')!;

    let pathParams = parsedPath[2] + '/' + parsedPath[3];
    let path = '/session';
    let url = this.searchOpenUrl + pathParams + path;
    return httpClient.get(url).then((e: any) => {
      console.log(e.data);
      return e.data;
    });
  }

  public async addItemMultisize(obj: any): Promise<any> {
    let id = 'USERIDAUTH0'; //:invId
    let path = '/addPurchases';
    let url = this.searchUrl + id + path;
    return httpClient.post(url, obj).then((e: any) => e.data);
  }

  public async deletePurchase(itemsIds: Array<string>): Promise<any> {
    let id = 'USERIDAUTH0'; //:invIdId
    let url = this.searchUrl + id;
    let body = {
      itemsIds: itemsIds,
    };
    return httpClient.delete(url, { data: body }).then((e: any) => e.data);
  }

  public async updatePurchase(itemId: string, item: Record<any, any>): Promise<any> {
    let id = 'USERIDAUTH0'; //:invIdId
    let itemPath = '/' + itemId;
    let url = this.searchUrl + id + itemPath;
    return httpClient.patch(url, item).then((e: any) => e.data);
  }

  public async export(itemsIds: Array<string>, filters?: InventoryFiltersInterface) {
    //let orderBy = (sortFilters?.find(e=>e.lastSelect)!.value ? "+": "-") + (sortFilters?.find(e=>e.lastSelect)!.name);

    let id = 'USERIDAUTH0'; //:invIdId

    let path = '/actions/share';
    // let page = "?page="+ (paginationFilter?.page ? paginationFilter?.page + 1 : "1") + "&";
    // let sizePage = "itemperpage=" + this.MAX_ELEMENTS;
    let filtersBody = {
      Name: filters?.searchFilters || '',
      PurchaseDate: filters?.purDateFilter || [],
      ShippingDate: [],
      SaleDate: [],
      Slug: 'inventory',
      Sizes: filters?.sizeFilter || [],
      PurchaseStore: filters?.purStoreFilter || [],
      ListedOn: [],
      SkipDuplicates: filters?.hideDuplicates,
      With: 'purchase.item,purchase.variant,variant.size,purchase.listings,purchase.sale',
    };
    let url = this.searchUrl + id + path;
    let body = {
      itemsIds: itemsIds,
      filters: filtersBody,
    };
    return httpClient.post(url, body).then((e) => {
      //   const href = URL.createObjectURL(e.data);
      //   const link = document.createElement('a');
      //   link.href = href;
      //   link.setAttribute('download', 'Inventory.csv');
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      //   URL.revokeObjectURL(href);
      return e.data;
    });
  }
}
