import { DashboardInterface } from "../../../../../services/DashboardService";
import { formatNumber } from "../../../../../utility/commonUtils";

export interface CardsInterface {
    real_time: Array<CardInterface>;
    all_time: Array<CardInterface>;
}

export interface CardInterface {
    name: string;
    fa_icon: string;
    value: any;
    description?: string;
    format: boolean;
}

export default class CardShower {

    cards: CardsInterface = {
        real_time: [
            {
                name: 'inventoryValue',
                fa_icon: 'fa-solid fa-sack-dollar',
                value: 'N/A',
                format: true
            },
            {
                name: 'retailPrice',
                fa_icon: 'fa-regular fa-credit-card',
                value: 'N/A',
                description: "Money spent for the items you actually have in your inventory",
                format: true
            },
            {
                name: 'inventoryItems',
                fa_icon: 'fa-solid fa-clipboard-list',
                value: 'N/A',
                description: "Quantity of items you actually have in your inventory",
                format: false
            },
            {
                name: 'estimatedProfit',
                fa_icon: 'fa-solid fa-euro-sign',
                value: 'N/A',
                format: true
            },
        ],
        all_time: [
            {
                name: '_payoutsAmount',
                fa_icon: 'fa-solid fa-sack-dollar',
                value: 'N/A',
                description: "Money earned for your sold items",
                format: true
            },
            {
                name: '_retailPrice',
                fa_icon: 'fa-regular fa-credit-card',
                value: 'N/A',
                description: "Money spent for all the items you have purchased",
                format: true
            },
            {
                name: '_otherExpenses',
                fa_icon: 'fa-solid fa-cart-shopping',
                value: 'N/A',
                description: "Money spent on all the other things related to your business (bot renew, proxy, server)",
                format: true
            },
            {
                name: '_otherIncome',
                fa_icon: 'fa-regular fa-chart-line',
                value: 'N/A',
                description: "Money earned on all the other things related to your business (slot, etc)",
                format: true
            },
            {
                name: '_soldPairs',
                fa_icon: 'fa-solid fa-truck-arrow-right',
                value: 'N/A',
                description: "Quantity of items you have bought",
                format: false
            },
            {
                name: '_purchasedPairs',
                fa_icon: 'fa-solid fa-basket-shopping',
                description: "Quantity of items you have sold",
                value: 'N/A',
                format: false
            },
            {
                name: '_balance',
                fa_icon: 'fa-solid fa-scale-balanced',
                value: 'N/A',
                description: "Payouts amount + Other incomes - Retail Price amount - Other expenses",
                format: true
            },
            {
                name: '_netProfit',
                fa_icon: 'fa-solid fa-euro-sign',
                description: "Profit on sold items",
                value: 'N/A',
                format: true
            }
        ]
    };


    public getCards(dashInfo: Array<{ name: string, value: any }>): CardsInterface {
        this.cards.real_time.forEach(e => {
            e.value = dashInfo.find(eD => eD.name === e.name)?.value ?? 0;
            e.value = e.format ? formatNumber(e.value) : e.value;
        })
        this.cards.all_time.forEach(e => {
            e.value = dashInfo.find(eD => eD.name === e.name)?.value ?? 0;
            e.value = e.format ? formatNumber(e.value) : e.value;
        })
        return this.cards;
    }

}