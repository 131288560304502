import httpClient from "../../utility/httpClient"
import {InventoryListInterface} from "../InventoryInterfaces";
import {FiltersInterface} from "../../utility/filtersUtils";
import { WtbsFilters as WtbsFiltersInterface } from "./WtbsFilterInterfaces";
import { PaginationFiltersInterface } from "../CommonInterfaces";

export default class WTBService {

    MAX_ELEMENTS: number = 15;

    searchUrl = "/api/v1/wtb/";

    public async getProducts(sortFilters?: FiltersInterface[], filters?: WtbsFiltersInterface, paginationFilter?: PaginationFiltersInterface): Promise<InventoryListInterface> {

        let orderBy = (sortFilters?.find(e=>e.lastSelect)!.value ? "+": "-") + (sortFilters?.find(e=>e.lastSelect)!.name);

        let id = 'USERIDAUTH0'; //:invIdId
        let path = "/filterWtb";
        let page = "?page="+ (paginationFilter?.page ? paginationFilter?.page + 1 : "1") + "&";
        let sizePage = "itemperpage=" + this.MAX_ELEMENTS;
        let body = {
            "Name": filters?.searchFilters || "",
            "PurchaseDate": [],
            "ShippingDate": [],
            "SaleDate": [],
            "Slug": "wtb",
            "Sizes": filters?.sizeFilter || [],
            "PurchaseStore": [],
            "ListedOn": [],
            "OrderBy": orderBy,
            "SkipDuplicates": filters?.hideDuplicates,
            "With": "wtb.item,wtb.variant,variant.size"
        };
        let url = this.searchUrl + id + path + page + sizePage;
        return httpClient.post(url, body).then((e: any)=> {
            return e.data
        });
    }

    public async addItemMultisize(obj: any): Promise<any> {
        let id = 'USERIDAUTH0'; //:invId
        let path = "/addWtbs";
        let url = this.searchUrl + id + path;
        return httpClient.post(url, obj).then((e: any)=>e.data);
    }

    public async deleteWtb(itemsIds: Array<string>): Promise<any> {
        let id = 'USERIDAUTH0'; //:invIdId
        let url = this.searchUrl + id;
        let body = {
            'itemsIds': itemsIds
        }
        return httpClient.delete(url, {data: body}).then((e: any)=>e.data);
    }

    public async updateWtb(itemId: string, item: Record<any, any>): Promise<any> {
        let id = 'USERIDAUTH0'; //:invIdId
        let itemPath = ('/' + itemId);
        let url = this.searchUrl + id + itemPath;
        return httpClient.patch(url, item).then((e: any)=>e.data);
    }

}