import axios from 'axios';
import { jwtDecode } from "jwt-decode";

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});
export const addAccessTokenInterceptor = () => {
    httpClient.interceptors.request.use(async (config) => {
        const jwt = localStorage.getItem('UserJWT');
        if (!jwt) {
            window.location.href = window.location.origin;
        }
        const decoded = jwtDecode(jwt!);
        let userId = decoded.sub;
        if (config.url?.includes("USERIDAUTH0") && userId) {
            config.url = config.url.replace("USERIDAUTH0", userId);
        }
        config.headers.Authorization = `Bearer ${jwt}`;
        return config;
    });
};

export const logout = () => {
    localStorage.setItem('UserJWT', '');
    window.location.href = window.location.origin;
}

export default httpClient;