import React, { useCallback, useEffect, useMemo, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import './Sell.scss';
import Filters from '../../../common/filters/Filters';
import RowFilters from '../../../common/rowFilters/rowFilters';
import FiltersUtils, { FiltersInterface as SortFiltersInterface } from '../../../../utility/filtersUtils';
import RowItem from '../../../common/rowItem/rowItem';
import AddModal_Inventory from '../../../modals/AddModal_Inventory/AddModal_Inventory';
import SellListService from '../../../../services/sell/SellListService';
import { InventoryListInterface, InventoryResultInterface } from '../../../../services/InventoryInterfaces';
import Loader from '../../../common/loader/Loader';
import { SellFilters } from '../../../../services/sell/SellFilterInterfaces';
import { PaginationFiltersInterface } from '../../../../services/CommonInterfaces';
import PaginationFilters from '../../../common/paginationFilters/PaginationFilters';
import { ExpenseIncome } from '../../../../services/expenseincome/ExpenseIncomeInterface';
import { checkIfArrayAreEquals, makeUpperCase } from '../../../../utility/commonUtils';
import WarningModal from '../../../modals/WarningModal/WarningModal';
import LazyImage from '../../../common/lazyimage/LazyImage';
import Checkbox from '../../../common/checkbox/Checkbox';
import AddModal_WTB from '../../../modals/AddModal_WTB/AddModal_WTB';
import { toast } from 'react-toastify';
import ToastHandler from '../../../../utility/ToastHandler/ToastHandler';

const Sell: React.FC = () => {
  //Service
  const sellService = useMemo(() => {
    let service = new SellListService();
    service.MAX_ELEMENTS = FiltersUtils.initializeItemPerPage();
    return service;
  }, []);

  const [MAX_ELEMENTS, setMaxElements] = useState<number>(FiltersUtils.initializeItemPerPage());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<SellFilters>();
  const [sortFilters, setSortFilters] = useState<Array<SortFiltersInterface>>(FiltersUtils.initializeFilters('sell'));
  const [paginationFilters, setPagination] = useState<PaginationFiltersInterface>({ page: 0, count: undefined });
  const [list, setList] = useState<InventoryListInterface>();
  const [listItems, setListItems] = useState<Array<InventoryResultInterface>>([]);

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<{ show: boolean; message: string; item: any }>({ show: false, message: '', item: null });
  const [hideInfo, setHideInfo] = useState(false);

  const getList = useCallback(
    async (sortFilters?: SortFiltersInterface[], filters?: SellFilters, paginationFilters?: PaginationFiltersInterface) => {
      setIsLoading(true);
      return await sellService.getProducts(sortFilters, filters, paginationFilters).then((_list) => {
        setList(_list);
        setPagination((prev) => {
          return { ...prev, count: _list.itemsCount };
        });
        setListItems(_list.results ? [..._list.results] : []);
        setIsLoading(false);
      });
    },
    [sellService]
  );

  useEffect(() => {
    getList(sortFilters, filters, paginationFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getList, sortFilters, filters, paginationFilters.page]);

  useEffect(() => {
    setPagination((prev) => {
      return { ...prev, page: 0 };
    });
  }, [filters]);

  const refreshList = useCallback(async () => {
    await getList(sortFilters, filters, paginationFilters);
  }, [filters, getList, paginationFilters, sortFilters]);

  const handleFilters = (filter: any) => {
    if (filter) {
      setFilters(filter);
      return;
    }
    setFilters(undefined);
  };

  const handlePaginationFilter = (pagFilter: number) => {
    if (pagFilter !== undefined) {
      setPagination((prev) => {
        return { ...prev, page: pagFilter };
      });
      return;
    }
  };

  const handleSortFilter = (filter?: string) => {
    if (!filter) {
      setSortFilters([...FiltersUtils.initializeFilters('wtbs')]);
      return;
    }
    if (filter === 'hideInfo') {
      setHideInfo((prev) => {
        return !prev;
      });
      return;
    }
    if (sortFilters) {
      let filterToUpdate = sortFilters.find((e) => e.name === filter);
      if (filterToUpdate) {
        filterToUpdate.value = !filterToUpdate!.value;
        filterToUpdate.lastSelect = true;
        sortFilters.forEach((e) => {
          if (e.name !== filterToUpdate!.name) {
            e.value = false;
            e.lastSelect = false;
          }
        });
        localStorage.setItem('filtersWtbs', JSON.stringify(sortFilters));
        setSortFilters([...sortFilters]);
      }
      return;
    }
    setSortFilters([]);
  };

  const openModal = (bool?: boolean) => {
    setShowAddModal(bool || !showAddModal);
  };

  const setItemPerPage = (pages: number) => {
    sellService.MAX_ELEMENTS = pages;
    localStorage.setItem('itemPerPage', pages.toString());
    setMaxElements(pages);
    setPagination((prev) => {
      return { ...prev, page: 0 };
    });
    refreshList();
  };

  const onAction = (action: string, row: any, confirmed?: boolean) => {
    if (action === 'sell') {
      if (!confirmed) {
        setWarningModal({ show: true, item: row, message: 'Are you sure you want to sell this item?' });
        return;
      }
      setWarningModal({ show: false, item: row, message: 'Are you sure you want to sell this item?' });
      sellService
        .sellProduct(row.userId, row.Id)
        .then((_list) => {
          ToastHandler.customToast({ message: 'Sell Completed, check Discord to finalize' });
        })
        .catch((e) => {
          if (e?.response?.data?.errorMessage) {
            ToastHandler.customToast({ message: 'Error: ' + makeUpperCase(e?.response?.data?.errorMessage) });
          } else {
            ToastHandler.customToast({ message: 'There was an error while selling item, contact assistance' });
          }
        });
    }
  };

  return (
    <>
      <div className="inventory">
        <Filters
          type={'sell'}
          openModal={() => openModal()}
          onChange={(args) => {
            handleFilters(args);
          }}
        />
        <RowFilters hideInfo={hideInfo} filters={sortFilters} handleFilterClick={(str) => handleSortFilter(str)} />
        {isLoading && (
          <div className="inventoryContainer d-flex justify-content-center align-items-center w-100">
            {<Loader bootstrapLoader customStyle={{ width: '36px', height: '36px', marginTop: '50px' }} />}
          </div>
        )}
        {!isLoading && listItems?.length > 0 && (
          <div className="inventoryList">
            {listItems.map((v, i) => (
              <RowItem
                hideInfo={hideInfo}
                onAction={(action) => onAction(action, v)}
                refresh={() => refreshList()}
                key={i}
                filters={sortFilters}
                type="sell"
                item={v}
              />
            ))}
          </div>
        )}
        {!isLoading && !list?.results && (
          <div className="inventoryContainer d-flex justify-content-center align-items-center w-100">
            {
              <span className="color-white font-weight-bold listMessage" onClick={() => openModal()}>
                Your list is clear, <span className="text-decoration-underline cursor-pointer">Add a Wtb</span>
              </span>
            }
          </div>
        )}
        {!isLoading && <div className="divider" />}
        {!isLoading && (
          <PaginationFilters
            count={paginationFilters.count}
            selected={paginationFilters.page!}
            itemPerPage={MAX_ELEMENTS}
            updateElements={(pages) => {
              setItemPerPage(pages);
            }}
            onChange={(res) => {
              handlePaginationFilter(res);
            }}
          />
        )}
      </div>

      {
        <WarningModal
          show={warningModal.show}
          onHide={(didChange?: boolean) => {
            if (didChange) {
              onAction('sell', warningModal.item, true);
              return;
            }
            setWarningModal({ message: warningModal.message, show: false, item: warningModal.item });
          }}>
          <div className="text-center">
            <div>{warningModal.message}</div>
            <div className="d-flex align-items-center justify-content-start mt-2 w-100">
              <LazyImage customClass="image" src={warningModal?.item?.item?.imgUrl} alt="Item" />
              <div className="ps-1 w-100 d-flex flex-column align-items-center justify-content-center" style={{ fontWeight: 600 }}>
                <div>{warningModal?.item?.item?.name}</div>
                <div>{warningModal?.item?.item?.sku}</div>
              </div>
            </div>
          </div>
        </WarningModal>
      }
    </>
  );
};

export default Sell;
