import httpClient from "../../utility/httpClient"
import {FiltersInterface} from "../../utility/filtersUtils";
import { PaginationFiltersInterface } from "../CommonInterfaces";
import { ExpenseIncomeFilters } from "./ExpenseIncomeFilterInterfaces";

export default class ExpenseIncomeListService {

    MAX_ELEMENTS: number = 15;

    searchUrl = "/api/v1/incomes-expenses/";

    public async getProducts(sortFilters?: FiltersInterface[], filters?: ExpenseIncomeFilters, paginationFilter?: PaginationFiltersInterface, type?: string): Promise<any> {

        let orderBy = (sortFilters?.find(e=>e.lastSelect)!.value ? "+": "-") + (sortFilters?.find(e=>e.lastSelect)!.name);

        let id = 'USERIDAUTH0'; //:invIdId
        let path = "/" + type + "/filter";
        let page = "?page="+ (paginationFilter?.page ? paginationFilter?.page + 1 : "1") + "&";
        let sizePage = "itemperpage=" + this.MAX_ELEMENTS;
        let body = {
            "Name": filters?.searchFilters || "",
            "PurchaseDate": filters?.dateFilter || [],
            "ShippingDate": [],
            "SaleDate": [],
            "Slug": "",
            "Sizes": [],
            "PurchaseStore": [],
            "ListedOn": [],
            "Services": filters?.income_expenseServicesFilter ?? [],
            "IncOrExp": filters?.income_expenseFilter ?? [],
            "OrderBy": orderBy,
            "With": ""
        };
        let url = this.searchUrl + id + path + page + sizePage;
        return httpClient.post(url, body).then((e: any)=> {
            return e.data
        });
    }

    public async addItemExpInc(obj: any): Promise<any> {
        let id = 'USERIDAUTH0'; //:invId
        let path = "/add";
        let url = this.searchUrl + id + path;
        return httpClient.post(url, obj).then((e: any)=>e.data);
    }

    public async updateExpInc(itemId: string, type: string, item: Record<any, any>): Promise<any> {
        let id = 'USERIDAUTH0'; //:invIdId
        let itemPath = ('/' + type + '/' + itemId);
        let url = this.searchUrl + id + itemPath;
        return httpClient.patch(url, item).then((e: any)=>e.data);
    }
    public async deleteExpInc(itemsIds: Array<string>, type: string, alsoStandard: boolean): Promise<any> {
        let id = 'USERIDAUTH0'; //:invIdId
        let itemPath = ('/' + type + '?alsoStandard=' + (alsoStandard.toString()));
        let url = this.searchUrl + id + itemPath;
        let body = {
            'itemsIds': itemsIds
        }
        return httpClient.delete(url, {data: body}).then((e: any)=>e.data);
    }
}