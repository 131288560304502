import React, {useEffect} from "react";
import Modal from 'react-bootstrap/Modal';
import '../CommonModal.scss';
import './ImageModal.scss';


interface ImageModalProps {
    show: boolean;
    onHide: (didChange?: boolean) => void;
    children: React.ReactNode
}

const ImageModal: React.FC<ImageModalProps> = (props) => {

    useEffect(()=>{
    },[])

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName={"commonModal"}
            backdropClassName={"commonModalBackdrop"}
            dialogClassName={"ImageModalDialog"}
        >
            <Modal.Body>
                <>
                    <>
                        <div className={`ImageModal`}>
                            <div className={'containerModal'}>
                                <div className={"ps-3 pe-3 pt-3 pb-4 mb-1 color-white"}>
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </>
                </>
            </Modal.Body>
        </Modal>
    );
}

export default ImageModal;