export interface ModuleInterface {
  name: string;
}

export default class ModulesInfo {
  public getModules = (): Array<ModuleInterface> => {
    return this.modules;
  };

  private modules: Array<ModuleInterface> = [
    {
      name: 'StepUp',
    },
    {
      name: 'StockX',
    },
    {
      name: 'Alias',
    },
    {
      name: 'Wethenew',
    },
    {
      name: 'Sneakit',
    },
    {
      name: 'Klekt',
    },
  ];

  public getBasicRetailers(): Array<string> {
    return [
      'Aboutyou',
      'Adidas',
      'Afew',
      'Asos',
      'Awlab',
      'Basket4ballers',
      'Bstn',
      'Cisalfa',
      'Cornerstreet',
      'Courir',
      'End',
      'Footdistrict',
      'Footlocker',
      'Footpatrol',
      'Here',
      'Jd',
      'Kickz',
      'Kitheu',
      'Luisaviaroma',
      'Mytheresa',
      'Newbalance',
      'Nike',
      'Off-White',
      'Office',
      'Offspring',
      'Overkill',
      'Patta',
      'Prodirect',
      'Sivasdescalzo',
      'Size?',
      'Slamjam',
      'Snipes',
      'Sns',
      'Solebox',
      'Starcow',
      'Stussy',
      'Sugar',
      'Supreme',
      'Susi',
      'Zalando',
    ];
  }

  public getBasicSizes(): any {
    return {
      EU: {
        w: [
          '34',
          '34.5',
          '35',
          '35.5',
          '36',
          '36.5',
          '36 2/3',
          '37',
          '37 1/3',
          '37.5',
          '38',
          '38.5',
          '38 2/3',
          '39',
          '39 1/3',
          '39.5',
          '40',
          '40.5',
          '40 2/3',
          '41',
          '41 1/3',
          '41.5',
          '42',
          '42.5',
          '42 2/3',
          '43',
          '43 1/3',
          '43.5',
          '44',
          '44.5',
          '44 2/3',
          '45',
          '45 1/3',
          '45.5',
          '46',
          '46.5',
          '46 2/3',
          '47',
          '47 1/3',
          '47.5',
          '48',
          '48.5',
          '48 2/3',
          '49',
          '49 1/3',
          '50',
          '50.5',
          '50 2/3',
          '51',
          '51.5',
        ],
        gs: ['35.5', '36', '36.5', '36 2/3', '37', '37 1/3', '37.5', '38', '38.5', '38 2/3', '39', '39 1/3', '40'],
        td: [
          '16',
          '16.5',
          '17',
          '18',
          '18.5',
          '19',
          '19.5',
          '20',
          '21',
          '21.5',
          '22',
          '22.5',
          '23',
          '23.5',
          '24',
          '25',
          '25.5',
          '26',
          '26.5',
          '27',
          '27.5',
          '28',
          '28.5',
          '29',
          '29.5',
          '30',
          '30.5',
          '31',
          '31.5',
          '32',
          '33',
          '33.5',
          '34',
          '35',
        ],
        other: ['XXXL', 'XXXS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
        m: [
          '34',
          '34.5',
          '35',
          '35.5',
          '36',
          '36-37',
          '36.5',
          '36 2/3',
          '37',
          '37 1/3',
          '37-38',
          '37.5',
          '38',
          '38-39',
          '38.5',
          '38 2/3',
          '39',
          '39 1/3',
          '39-40',
          '39.5',
          '40',
          '40.5',
          '40 2/3',
          '41',
          '41 1/3',
          '41-42',
          '41.5',
          '42',
          '42-43',
          '42.5',
          '42 2/3',
          '43',
          '43 1/3',
          '43-44',
          '43.5',
          '44',
          '44.5',
          '44 2/3',
          '45',
          '45 1/3',
          '45-46',
          '45.5',
          '46',
          '46-47',
          '46.5',
          '46 2/3',
          '47',
          '47 1/3',
          '47.5',
          '48',
          '48-49',
          '48.5',
          '48 2/3',
          '49',
          '49 1/3',
          '49-50',
          '49.5',
          '50',
          '50-51',
          '50.5',
          '50 2/3',
          '51',
          '51 1/3',
          '51.5',
          '52',
          '52.5',
          '52 2/3',
          '53',
          '53 1/3',
          '54',
          '54 2/3',
          '55',
          '55 2/3',
        ],
      },
      US: {
        w: [
          '4W',
          '4.5W',
          '5W',
          '5.5W',
          '6W',
          '6.5W',
          '7W',
          '7.5W',
          '8W',
          '8.5W',
          '9W',
          '9.5W',
          '10W',
          '10.5W',
          '11W',
          '11.5W',
          '12W',
          '12.5W',
          '13W',
          '13.5W',
          '14W',
          '14.5W',
          '15W',
          '15.5W',
          '16W',
          '16.5W',
          '17W',
          '17.5W',
          '18W',
        ],
        gs: ['3.5Y', '4Y', '4.5Y', '5Y', '5.5Y', '6Y', '6.5Y', '7Y'],
        td: [
          '9K',
          '11K',
          '6.5K',
          '1K',
          '6K',
          '5.5K',
          '10K',
          '7.5K',
          '2K',
          '13.5K',
          '10.5K',
          '7K',
          '9.5K',
          '5K',
          '13K',
          '8K',
          '3K',
          '12.5K',
          '4K',
          '8.5K',
          '11.5K',
          '12K',
          '1C',
          '1Y',
          '1.5C',
          '1.5Y',
          '2C',
          '2Y',
          '2.5C',
          '2.5Y',
          '3C',
          '3Y',
          '3.5C',
          '4C',
          '4.5C',
          '5C',
          '5.5C',
          '6C',
          '6.5C',
          '7C',
          '7.5C',
          '8C',
          '8.5C',
          '9C',
          '9.5C',
          '10C',
          '10.5C',
          '11C',
          '11.5C',
          '12C',
          '12.5C',
          '13C',
          '13.5C',
        ],
        other: ['XXXL', 'XXXS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
        m: [
          '3',
          '3.5',
          '4',
          '4.5',
          '5',
          '5.5',
          '6',
          '6.5',
          '7',
          '7.5',
          '8',
          '8.5',
          '9',
          '9.5',
          '10',
          '10.5',
          '11',
          '11.5',
          '12',
          '12.5',
          '13',
          '13.5',
          '14',
          '14.5',
          '15',
          '15.5',
          '16',
          '16.5',
          '17',
          '17.5',
          '18',
          '19',
          '20',
        ],
      },
      CM: {
        td: [
          '7',
          '7.5',
          '8',
          '8.1',
          '8.5',
          '9',
          '9.5',
          '9.8',
          '10',
          '10.5',
          '10.6',
          '11',
          '11.5',
          '12',
          '12.3',
          '12.5',
          '12.8',
          '13',
          '13.2',
          '13.5',
          '13.6',
          '14',
          '14.5',
          '14.9',
          '15',
          '15.3',
          '15.5',
          '15.7',
          '16',
          '16.1',
          '16.5',
          '16.6',
          '17',
          '17.4',
          '17.5',
          '17.8',
          '18',
          '18.3',
          '18.5',
          '18.7',
          '19',
          '19.1',
          '19.5',
          '20',
          '20.4',
          '20.5',
          '20.8',
          '21',
          '21.2',
          '21.5',
          '22',
        ],
        other: ['XXXL', 'XXXS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
        m: [
          'n/a',
          '21.1',
          '21.5',
          '21.6',
          '21.9',
          '22',
          '22.1',
          '22.4',
          '22.5',
          '22.8',
          '22.9',
          '23',
          '23.2',
          '23.3',
          '23.5',
          '23.6',
          '23.8',
          '24',
          '24.1',
          '24.2',
          '24.5',
          '24.6',
          '24.9',
          '25',
          '25.25',
          '25.3',
          '25.5',
          '25.8',
          '25.9',
          '26',
          '26.2',
          '26.3',
          '26.5',
          '26.6',
          '26.7',
          '27.0',
          '27',
          '27.1',
          '27.4',
          '27.5',
          '27.6',
          '27.9',
          '28',
          '28.25',
          '28.3',
          '28.4',
          '28.5',
          '28.7',
          '28.8',
          '29',
          '29.1',
          '29.3',
          '29.5',
          '29.6',
          '29.7',
          '30',
          '30.1',
          '30.4',
          '30.5',
          '31',
          '31.2',
          '31.4',
          '31.5',
          '31.8',
          '32',
          '32.5',
          '32.6',
          '33',
          '33.5',
          '34',
          '34.3',
          '34.5',
          '35',
          '35.2',
          '35.5',
          '36',
          '37',
          '38',
        ],
        w: [
          '21',
          '21.1',
          '21.5',
          '21.9',
          '22',
          '22.1',
          '22.4',
          '22.5',
          '22.75',
          '22.8',
          '22.9',
          '23',
          '23.2',
          '23.3',
          '23.5',
          '23.6',
          '23.8',
          '24',
          '24.1',
          '24.2',
          '24.5',
          '24.6',
          '24.9',
          '25',
          '25.3',
          '25.5',
          '25.75',
          '25.8',
          '25.9',
          '26',
          '26.2',
          '26.3',
          '26.5',
          '26.6',
          '26.7',
          '27.0',
          '27',
          '27.1',
          '27.4',
          '27.5',
          '27.6',
          '27.9',
          '28',
          '28.3',
          '28.4',
          '28.5',
          '28.7',
          '28.75',
          '28.8',
          '29',
          '29.1',
          '29.3',
          '29.5',
          '29.7',
          '30',
          '30.1',
          '30.4',
          '30.5',
          '31',
          '31.2',
          '31.5',
          '32',
          '32.5',
          '33',
          '33.5',
          '34',
          '34.5',
          '35',
        ],
        gs: [
          '21.5',
          '21.6',
          '22',
          '22.1',
          '22.2',
          '22.5',
          '22.86',
          '22.9',
          '23',
          '23.17',
          '23.3',
          '23.49',
          '23.5',
          '23.8',
          '24',
          '24.13',
          '24.2',
          '24.4',
          '24.5',
          '24.6',
          '24.76',
          '25',
        ],
      },
      UK: {
        td: [
          '8K',
          '6.5K',
          '10.5K',
          '8.5K',
          '4K',
          '6K',
          '1.5K',
          '13.5K',
          '5.5K',
          '7K',
          '5K',
          '9.5K',
          '1K',
          '10K',
          '13K',
          '12.5K',
          '9K',
          '7.5K',
          '3K',
          '0K',
          '11K',
          '2.5K',
          '2K',
          '12K',
          '11.5K',
          '0.5',
          '1',
          '1.5',
          '1.5Y',
          '2',
          '2.5',
          '2.5Y',
          '3',
          '3.5',
          '4',
          '4.5',
          '5',
          '5.5',
          '6',
          '6.5',
          '7',
          '7.5',
          '8',
          '8.5',
          '9',
          '9.5',
          '10',
          '10.5',
          '11',
          '11.5',
          '12',
          '12.5',
          '13',
          '13.5',
        ],
        other: ['XXXL', 'XXXS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
        m: [
          '2.5',
          '3',
          '3.5',
          '4',
          '4.5',
          '5',
          '5.5',
          '6',
          '6.5',
          '7',
          '7.5',
          '8',
          '8.5',
          '9',
          '9.5',
          '10',
          '10.5',
          '11',
          '11.5',
          '12',
          '12.5',
          '13',
          '13.5',
          '14',
          '14.5',
          '15',
          '15.5',
          '16',
          '16.5',
          '17',
          '17.5',
          '18',
          '18.5',
          '19',
          '19.5',
        ],
        w: [
          '2',
          '2.5',
          '3',
          '3.5',
          '4',
          '4.5',
          '5',
          '5.5',
          '6',
          '6.5',
          '7',
          '7.5',
          '8',
          '8.5',
          '9',
          '9.5',
          '10',
          '10.5',
          '11',
          '11.5',
          '12',
          '12.5',
          '13',
          '13.5',
          '14',
          '14.5',
          '15',
          '15.5',
        ],
        gs: ['3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5'],
      },
    };
  }
}
