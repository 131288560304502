import dark from '../themes/json/dark.json';
import light from '../themes/json/light.json';

interface Theme {
    name: string;
    isActive: boolean;
}

export default class ThemeHandler {

    private static instance: ThemeHandler;
    private static themeName: string = 'dark';

    private constructor(themeName?: string) {
        try {
            if (!themeName || !localStorage.getItem(themeName)) return;
            let localStorageTheme: Theme = JSON.parse(localStorage.getItem(themeName)!);
            if (localStorageTheme.isActive) {
                this.setTheme(localStorageTheme.name);
            }
        } catch (err) {
            this.setTheme();
        }
        this.startListener();
    }

    public static getInstance(): ThemeHandler {
        if (!ThemeHandler.instance) {
            ThemeHandler.instance = new ThemeHandler();
        }

        return ThemeHandler.instance;
    }

    public static getTheme(): string {
        return this.themeName;
    }

    public static getGlobalColor(name: string): string | undefined {
        try {
            return getComputedStyle(document.documentElement).getPropertyValue(name);
        } catch {}
    }

    pickupJSON = (themeName?: string): Object => {
        switch (themeName) {
            case 'dark':
                return dark;
            case 'light':
                return light;
            default:
                return dark;
        }
    }

    setTheme = (name?: string) => {
        name ? (ThemeHandler.themeName = name) : (ThemeHandler.themeName = 'dark');
        let JSON = this.pickupJSON(name) as Record<string, string>;
        document.documentElement.style.setProperty('--text-base-color', JSON['--text-base-color']);
        document.documentElement.style.setProperty('--background-color-main', JSON['--background-color-main']);
        document.documentElement.style.setProperty('--main-color', JSON['--main-color']);
    }

    startListener = () => {
        document.addEventListener("themeHandler", (event) => {
            try {
                let themeName: string = event.type;
                if (!themeName || !localStorage.getItem(themeName)) return;
                let localStorageTheme: Theme = JSON.parse(localStorage.getItem(themeName)!);
                if (localStorageTheme.isActive) {
                    this.setTheme(localStorageTheme.name);
                }
            } catch (err) {
                this.setTheme();
            }
        })
    }

}
