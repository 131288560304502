import React, { useState, useEffect } from 'react';
import { Variant } from "../../../services/CommonInterfaces";
import "./SizePicker.scss";

interface SizePickerPropsInterface {
    variants: { [key: string]: Variant };
    qty?: Map<string, number>;
    onChange: (args: Map<string, number>) => void;
}

const SizePicker: React.FC<SizePickerPropsInterface> = (props) => {

    const [pickedSize, setPickedSize] = useState<Map<string, number>>(new Map());

    const sizeHandler = (action: string, variantId: string, str?: string) => {
        if (action === "input") {
            setPickedSize((map) => { map.set(variantId, Number(str)); return new Map(map) });
            props.onChange(pickedSize);
            return;
        }
        let selected: number = pickedSize.get(variantId) || 0;
        if (action === "add" && selected < 99) {
            setPickedSize((map) => { map.set(variantId, selected + 1); return new Map(map) });
            props.onChange(pickedSize);
            return;
        }
        if (action === "remove" && selected > 0) {
            setPickedSize((map) => { map.set(variantId, selected - 1); return new Map(map) });
            props.onChange(pickedSize);
            return;
        }
    }

    useEffect(() => {
        if (props.qty) {
            setPickedSize(new Map(Object.entries(props.qty)));
        }
    }, [props.qty])


    return (
        <>
            <div className={"sizePickerInventory col-12 col-sm-6"}>
                <div className={"sizeLabels"}>
                    <div className={"row ms-1 me-1 d-flex align-content-center"}>
                        <div className={"col-2 g-0 text-center"}>
                            <div className={"label"}>EU</div>
                        </div>
                        <div className={"col-2 g-0 text-center"}>
                            <div className={"label"}>US</div>
                        </div>
                        <div className={"col-2 g-0 text-center"}>
                            <div className={"label"}>UK</div>
                        </div>
                        <div className={"col-2 g-0 text-center"}>
                            <div className={"label"}>CM</div>
                        </div>
                        <div className={"col-4 g-0 text-center"}></div>
                    </div>
                </div>
                <div className={"sizeList"}>
                    {[...Object.keys(props.variants).sort().map((v, i) => {
                        return <ValuePicker sizeHandler={(type, sizeId, str) => sizeHandler(type, sizeId, str)} pickedSize={(pickedSize.get(props.variants[v].sizeId) || "0").toString()} variants={props.variants} v={v} />;
                    })]}
                </div>
            </div>
        </>
    );
}

export default SizePicker;

interface ValuePickerProps {
    pickedSize: string;
    variants: { [p: string]: Variant };
    v: string;
    sizeHandler: (type: string, sizeId: string, str?: string) => void;
}

const ValuePicker: React.FC<ValuePickerProps> = ({ pickedSize, variants, v, sizeHandler }) => {

    const [enableInput, setEnableInput] = useState(false);
    const [input, setInputValue] = useState(pickedSize);

    const setInput = (str: string) => {
        setInputValue(str);
        sizeHandler("input", variants[v].sizeId, str);
    }

    useEffect(() => {
        setInputValue(pickedSize);
    }, [pickedSize])

    return (
        <>
            <div key={variants[v].sizeId} className={"row ms-1 me-1 d-flex align-content-center item"}>
                <div className={"col-2 g-0 text-center"}>
                    <div className={"label"}>{variants[v].size.EU}</div>
                </div>
                <div className={"col-2 g-0 text-center"}>
                    <div className={"label"}>{variants[v].size.US}</div>
                </div>
                <div className={"col-2 g-0 text-center"}>
                    <div className={"label"}>{variants[v].size.UK}</div>
                </div>
                <div className={"col-2 g-0 text-center"}>
                    <div className={"label"}>{variants[v].size.CM}</div>
                </div>
                <div className={"col-4 g-0 text-center d-flex justify-content-evenly align-items-center counter"}>
                    <i className="fa-solid fa-minus cursor-pointer" onClick={() => { setEnableInput(false); sizeHandler("remove", variants[v].sizeId) }}></i>
                    {!enableInput && <span onClick={() => setEnableInput(true)} className={"font-weight-bolder"}>{input}</span>}
                    {enableInput && <input autoFocus className={"inputPicker"} value={input} onChange={(event) => { setInput(event.target.value) }} />}
                    <i className="fa-solid fa-plus cursor-pointer" onClick={() => { setEnableInput(false); sizeHandler("add", variants[v].sizeId) }}></i>
                </div>
            </div>
        </>
    )
}