import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import i18_en from "./assets/i18n/en/all.json";
import i18_it from "./assets/i18n/it/all.json";
import { BrowserRouter } from 'react-router-dom';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                all: i18_en,
                dashboard: i18_en.dashboard,
                settings: i18_en.settings
            },
            it: {
                all: i18_it,
                dashboard: i18_it.dashboard,
                settings: i18_it.settings
            },
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    }).then();

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
