import React, { useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import '../CommonModal.scss';
import './CustomModal.scss';


interface CustomModalProps {
    show: boolean;
    onHide: (didChange?: boolean) => void;
    children: React.ReactNode
}

const CustomModal: React.FC<CustomModalProps> = (props) => {

    useEffect(() => {
    }, [])

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName={"commonCustomModal"}
            backdropClassName={"commonModalBackdrop"}
            dialogClassName={"CustomModalDialog"}
        >
            <Modal.Body>
                <>
                    <>
                        {props.children}
                    </>
                </>
            </Modal.Body>
        </Modal>
    );
}

export default CustomModal;