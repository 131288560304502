import httpClient from "../../utility/httpClient"

export default class ScannerService {

    MAX_ELEMENTS: number = 15;

    searchUrl = "/api/v1/items/";

    ///api/v1/items/:userId/barcode/:barcodeId?with=

    public async searchScannedProducts(code: string): Promise<{item?: any, variant?: any}> {
        let id = 'USERIDAUTH0'
        let path = "/barcode/" + code;
        let params = "?with=item.variants,variant.size";
        let url = this.searchUrl + id + path + params;
        return httpClient.get(url).then(e => e.data);
    }

    public async searchAllScannedProducts(codes: Array<string | number>): Promise<Array<any>> {
        let id = 'USERIDAUTH0'
        let path = "/barcodes?ids=" + codes.join(",");
        let params = "&with=item.variants,variant.size";
        let url = this.searchUrl + id + path + params;
        return httpClient.get(url).then(e => e.data);
    }
}