import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from './card/Card';
import './Dashboard.scss';
import CardShower, { CardInterface } from './utils/card_shower';
import DashboardService from '../../../../services/DashboardService';
import Loader from '../../../common/loader/Loader';
import DatePickerComponent from '../../../common/datepicker/DatePicker';
import Button from '../../../common/button/Button';
import moment from 'moment';

const Dashboard: React.FC = () => {
  //Service
  const dashboardService = useMemo(() => {
    return new DashboardService();
  }, []);

  const { t } = useTranslation('dashboard');
  const cardShower = new CardShower();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [info, setInfo] = useState<Array<{ name: string; value: any }>>();

  // Filters
  const [dateFilter, setDateFilter] = useState<Array<any>>();

  const getDashInfo = useCallback(async () => {
    setIsLoading(true);
    return await dashboardService.getDashInfo(dateFilter).then((_list) => {
      let _cards: Array<{ name: string; value: any }> = [];
      Object.entries(_list.inventory.realTime).forEach((e) => {
        _cards.push({
          name: e[0],
          value: e[1]?.value || e[1]?.value === 0 ? e[1].value + ' ' + (e[1]?.currency || '') : e[1],
        });
      });
      Object.entries(_list.inventory.allTime).forEach((e) => {
        _cards.push({
          name: '_' + e[0],
          value: e[1]?.value || e[1]?.value === 0 ? e[1].value + ' ' + (e[1]?.currency || '') : e[1],
        });
      });
      setInfo(_cards);
      setIsLoading(false);
    });
  }, [dashboardService, dateFilter]);

  useEffect(() => {
    console.log(dateFilter);
    getDashInfo();
  }, [getDashInfo, dateFilter]);

  useEffect(() => {
    const startMonth = moment().startOf('month').valueOf();
    const endMonth = moment().endOf('month').valueOf();
    setDateFilter([startMonth, endMonth]);
  }, []);

  return (
    <div className="dashboard">
      <div className="d-flex justify-content-start align-items-center">
        <div className="section-title">{t('realTimeOverview')}</div>
      </div>
      <div className="row d-flex justify-content-center align-items-center">
        {info ? (
          cardShower.getCards(info).real_time.map((card: CardInterface) => {
            return (
              <div key={card.name} className="col-6 col-sm-3 mb-2 ps-1 pe-1">
                <Card key={card.name} card={card} />
              </div>
            );
          })
        ) : (
          <div className="d-flex justify-content-center align-items-center w-100">
            {<Loader bootstrapLoader customStyle={{ width: '36px', height: '36px', marginTop: '60px', marginBottom: '60px' }} />}
          </div>
        )}
      </div>

      <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
        <div className="section-title">{t('allTimeRecap')}</div>
        <div className="d-flex justify-content-end align-items-center mt-1 mb-2">
          <div>
            <DatePickerComponent
              enableMonthSelection
              defaultValue={dateFilter}
              id="DashDateFilter"
              customClass="selectFilters ms-3"
              placeholder="Date"
              onChange={(str) => {
                setDateFilter(str);
              }}
            />
          </div>
          <div>
            <Button
              id="clearFilters"
              customClass="selectFilters ms-3 pe-2 text-center"
              onClick={() => {
                setDateFilter([]);
              }}>
              <div>
                Clear <i className="fa-solid fa-eraser"></i>
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center align-items-center">
        {!isLoading && info ? (
          cardShower.getCards(info).all_time.map((card: CardInterface) => {
            return (
              <div key={card.name} className="col-6 col-sm-3 mb-2 ps-1 pe-1">
                <Card key={card.name} card={card} />
              </div>
            );
          })
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            {<Loader bootstrapLoader customStyle={{ width: '36px', height: '36px', marginTop: '120px' }} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
