import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../CommonModal.scss';
import './WarningModal.scss';
import Button from '../../common/button/Button';

interface SettingsModalProps {
  show: boolean;
  onHide: (didChange?: boolean) => void;
  isLoading?: boolean;
  children: React.ReactNode;
  containerClass?: string;
}

const WarningModal: React.FC<SettingsModalProps> = (props) => {
  useEffect(() => {}, []);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={'commonModal ' + (props.containerClass ?? '')}
      backdropClassName={'commonModalBackdrop'}
      dialogClassName={'warningModalDialog'}>
      <Modal.Body>
        <>
          <>
            <div className={`warningModal`}>
              <div className={'containerModal'}>
                <div className={'closeButton'} onClick={() => props.onHide()}>
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className={'w-100 h6 text-center color-white'}>Warning</div>
                <div className={'mt-1 ps-3 pe-3 pt-4 pb-4 color-white'}>{props.children}</div>
                <div className="w-100 d-flex justify-content-center align-items-center">
                  <div className={'ps-3 pe-3 color-white text-center'}>
                    <Button value={'Cancel'} id={'cancelChange'} onClick={() => props.onHide(false)} />
                  </div>
                  <div className={'ps-3 pe-3 color-white text-center'}>
                    <Button value={'Confirm'} customClass="bg-danger" id={'confirmChange'} onClick={() => props.onHide(true)} shouldLoad={props.isLoading} />
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default WarningModal;
