import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../CommonModal.scss';
import './AddIncomesExpensesModal.scss';
import AutoComplete from '../../common/autocomplete/AutoComplete';
import Button from '../../common/button/Button';
import SizePicker from '../../common/sizepicker/SizePicker';
import Logo from '../../../assets/images/logo/transparent-logo.png';
import Input from '../../common/input/Input';
import InventoryListService from '../../../services/inventory/InventoryListService';
import moment from 'moment/moment';
import Select from '../../common/select/Select';
import UserDataStorage from '../../../singletonStorage/userDataStorage';
import ToastHandler from '../../../utility/ToastHandler/ToastHandler';
import ExpenseIncomeListService from '../../../services/expenseincome/ExpenseIncomeListService';
import Switch from '../../common/switch/Switch';
import IncomesExpenses from '../../main/content/ExpenseIncome/IncomesExpenses';
import { ExpenseIncome } from '../../../services/expenseincome/ExpenseIncomeInterface';
import { getDateByTimeStamp } from '../../../utility/commonUtils';

interface AddModalProps {
  show: boolean;
  recurrent: boolean;
  item?: ExpenseIncome;
  onHide: () => void;
  onSubmit: () => void;
  update?: boolean;
}

const AddIncomesExpensesModal: React.FC<AddModalProps> = (props) => {
  //Service
  const incomeExpenseService = new ExpenseIncomeListService();
  const userData = UserDataStorage.getInstance();

  const [showWarningClose, setShowWarningClose] = useState<boolean>(false);

  const [service, setService] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [purchaseDate, setPurchaseDate] = useState<string>(moment(new Date()).format('DD-MM-YYYY'));
  const [purchasePrice, setPurchasePrice] = useState<{ value: string; cur?: string; index?: number }>();
  const [type, setType] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [recurrentTime, setRecurrentTime] = useState<number>(1);
  const [isRecurrent, setIsRecurrent] = useState<boolean>(props.recurrent);

  const [errors, setErrors] = useState({
    purchaseDate: '',
    purchasePrice: '',
    service: '',
    type: '',
    recurrentTime: '',
  });

  const handleClose = (forceClose?: boolean) => {
    if (forceClose || (props.update && props.recurrent)) {
      setPurchaseDate('');
      setPurchasePrice({ value: '', cur: userData.settingsStorage!['currency'] });
      setNotes('');
      setService('');
      setType('');
      setRecurrentTime(1);
      props.onHide();
      return;
    }
    if (purchaseDate || purchasePrice?.value || type || service) {
      setShowWarningClose(true);
      return;
    }
    props.onHide();
  };

  const addButton = async () => {
    const validPurDate = purchaseDate ? moment(purchaseDate, 'DD-MM-YYYY', true).isValid() : false;
    let price = '';
    if (!purchasePrice?.value?.includes(',')) {
      price = purchasePrice?.value + ',00';
    } else {
      if (purchasePrice?.value?.split(',')[1].length === 0) {
        price = purchasePrice?.value + '00';
      }
      if (purchasePrice?.value?.split(',')[1].length === 1) {
        price = purchasePrice?.value + '0';
      }
      if (purchasePrice?.value?.split(',')[1].length === 2) {
        price = purchasePrice?.value;
      }
    }
    if (!purchasePrice || !purchasePrice.value || purchasePrice.value === '' || !validPurDate || !service || !type || (isRecurrent && recurrentTime < 1)) {
      setErrors(() => {
        return {
          purchasePrice: !purchasePrice || !purchasePrice.value || purchasePrice.value === '' ? 'Purchase Price is required' : '',
          purchaseDate: !purchaseDate ? 'Invalid Date' : '',
          service: !service ? 'Service is required' : '',
          type: !type ? 'Type is required' : '',
          recurrentTime: isRecurrent && recurrentTime < 1 ? 'Recurrent time invalid or required' : '',
        };
      });
      return;
    }
    if (validPurDate && (isRecurrent ? recurrentTime >= 1 : true)) {
      setErrors({ purchaseDate: '', purchasePrice: '', service: '', type: '', recurrentTime: '' });
      let obj = {
        service: service,
        date: moment(purchaseDate, 'DD-MM-YYYY', true).toDate().getTime(),
        price: Number(Number(price?.replaceAll(',', '.')).toFixed(2)),
        currency: purchasePrice!.cur,
        type: type,
        notes: notes,
        isRecurrent: isRecurrent,
        description: description,
        recurrentTime: isRecurrent ? recurrentTime : null,
      };
      if (!props.update) {
        await incomeExpenseService.addItemExpInc(obj).then(() => {
          ToastHandler.customToast({ message: 'Item Added Successfully' });
          props.onSubmit();
          handleClose(true);
        });
      } else {
        await incomeExpenseService.updateExpInc(props.item?.id!, !isRecurrent ? 'standard' : 'recurrent', obj).then(() => {
          ToastHandler.customToast({ message: 'Item Updated Successfully' });
          props.onSubmit();
          handleClose(true);
        });
      }
    }
  };

  const options: Array<any> = [];
  userData.sessionStorage!['services'].forEach((e: string) => {
    options.push({ label: e, value: e });
  });

  useEffect(() => {
    const setError = (message: string) => {
      setErrors((prev) => {
        return {
          ...prev,
          purchaseDate: message,
        };
      });
    };

    if (purchaseDate && purchaseDate.length > 9) {
      const validDate = moment(purchaseDate, 'DD-MM-YYYY', true).isValid();
      if (!validDate) {
        setError('Missing or Incorrect Date, insert correct format: DD-MM-YYYY');
      } else {
        setError('');
      }
    } else {
      setError('');
    }
  }, [purchaseDate]);

  useEffect(() => {
    if (props.item) {
      setService(props.item.service ?? '');
      setType(props.item.type ?? '');
      setNotes(props.item.notes ?? '');
      setDescription(props.item.description ?? '');
      setPurchaseDate(props.item.date ? getDateByTimeStamp(props.item.date, true) : '');
      setPurchasePrice({ value: props.item.price?.value.toString() ?? '', cur: userData.settingsStorage ? userData.settingsStorage!['currency'] : 'EUR' });
      props.recurrent && setRecurrentTime(props.item.recurrentTime ? Number(props.item.recurrentTime) : 1);
      props.recurrent && setPurchaseDate(props.item.startDate ? getDateByTimeStamp(props.item.startDate, true) : '');
    }
    setIsRecurrent(props.recurrent);
  }, [props.show, props.recurrent]);

  useEffect(() => {
    let currency = userData.settingsStorage ? userData.settingsStorage!['currency'] : 'EUR';
    setPurchasePrice((prev) => {
      return { value: prev?.value ?? '', cur: currency };
    });
  }, [userData.settingsStorage]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={'commonModal'}
      backdropClassName={'commonModalBackdrop'}
      dialogClassName={'commonModalDialog'}>
      <Modal.Body>
        <>
          <>
            {showWarningClose && (
              <div className={'closeConfirm'}>
                <div className={'w-100 h-100 p-1 color-white d-flex flex-column justify-content-center align-items-center'}>
                  <div className={'w-100 p-2 mb-2 text-center message'}>You have pending changes, are you sure you want to close?</div>
                  <div className={'d-flex justify-content-center align-items-center'}>
                    <Button customClass={'closeConfirmButtons btn-dark'} id={'discard'} onClick={() => setShowWarningClose(false)} value={'Discard'} />
                    <Button customClass={'closeConfirmButtons bg-danger btn-danger'} id={'close'} onClick={() => handleClose(true)} value={'Close'} />
                  </div>
                </div>
              </div>
            )}
            <div className={`addModal ${showWarningClose ? 'blurred' : ''}`}>
              <div className={'containerModal'}>
                <div className={'closeButton'} onClick={() => handleClose()}>
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className={'w-100 h6 text-center color-white'}>Add Income/Expense</div>
                <div className={'mt-2 ps-3 pe-3 pt-4 pb-2 color-white'}>
                  <>
                    <div className={'w-100 h-100'}>
                      <div className={'mt-3 row pe-1'}>
                        <div className={'col-12 pe-1'}>
                          <label className={'labelModal'} htmlFor={'description'}>
                            Description
                          </label>
                          <Input
                            defaultValue={description ?? ''}
                            disabled={props.update && props.recurrent}
                            customClass={'smallPlaceholder'}
                            id={'description'}
                            onChange={(str) => setDescription(str)}
                          />
                        </div>
                        <div className={'col-12 pe-1 mb-2'}>
                          <label className={'labelModal'} htmlFor={'service'}>
                            Service
                          </label>
                          {props.update && props.recurrent ? (
                            <Input
                              disabled
                              type={'service'}
                              defaultValue={service ?? ''}
                              customClass={'smallPlaceholder'}
                              id={'service'}
                              onChange={(str) => {}}
                            />
                          ) : (
                            <AutoComplete customValues={options} defaultValue={service ?? ''} onSelect={(item) => setService(item.value)} />
                          )}
                        </div>
                        <div className={'col-4 pe-1'}>
                          <label className={'labelModal'} htmlFor={'purDate'}>
                            Date
                          </label>
                          <Input
                            disabled={props.update && props.recurrent}
                            type={'date'}
                            defaultValue={purchaseDate ?? ''}
                            error={errors.purchaseDate}
                            customClass={'smallPlaceholder'}
                            id={'purDate'}
                            placeholder={'DD-MM-YYYY'}
                            onChange={(str) => setPurchaseDate(str)}
                          />
                        </div>
                        <div className={'col-4 ps-1 pe-1'}>
                          <label className={'labelModal'} htmlFor={'purPrice'}>
                            Price
                          </label>
                          <Input
                            error={errors.purchasePrice}
                            customClass={'smallPlaceholder w-100'}
                            id={'purPrice'}
                            type={'price'}
                            onChangeSelect={(str) =>
                              setPurchasePrice((prev) => {
                                return {
                                  ...prev!,
                                  cur: str,
                                };
                              })
                            }
                            disabled={props.update && props.recurrent}
                            defaultValue={purchasePrice?.value ?? ''}
                            defaultValueSelect={purchasePrice?.cur}
                            valueSelect={userData.sessionStorage!['currencies']}
                            onChange={(str) =>
                              setPurchasePrice((prev) => {
                                return { ...prev!, value: str };
                              })
                            }
                          />
                        </div>
                        <div className={'col-4 ps-1 pe-1'}>
                          <label className={'labelModal'} htmlFor={'type'}>
                            Type
                          </label>
                          <Select
                            disabled={props.update && props.recurrent}
                            customClass={'d-block w-100'}
                            error={errors.type}
                            id={'type'}
                            selectedValue={type}
                            value={['Expense', 'Income']}
                            onChange={(str) => setType(str)}
                          />
                        </div>
                        {!props.update && (
                          <div className={'col-4 pt-3 ps-1 pe-1 d-flex justify-content-center align-items-center mt-3 mb-3'}>
                            <label className={'labelModal'} htmlFor={'isRecurrent'}>
                              Recurrent
                            </label>
                            <Switch customClass={'ms-2'} id={'isRecurrent'} value={isRecurrent} onChange={() => setIsRecurrent((prev) => !prev)} />
                          </div>
                        )}
                        {!props.update ? (
                          <div className={'col-8 ps-1 pe-1 mt-2 ' + (!isRecurrent ? 'blurCron' : '')}>
                            <label className={'labelModal'} htmlFor={'recurrDate'}>
                              Frequency
                            </label>
                            <Input
                              type={'dateCron'}
                              error={errors.recurrentTime}
                              defaultValue={recurrentTime}
                              customClass={'smallPlaceholder'}
                              id={'recurrDate'}
                              placeholder={'DD-MM-YYYY'}
                              onChange={(int) => {
                                setRecurrentTime(int);
                              }}
                            />
                          </div>
                        ) : props.recurrent ? (
                          <div className={'col-12 ps-1 pe-1 d-flex justify-content-center mt-4 align-items-center'}>
                            <div className="text-white">Frequency: {recurrentTime ? 'Every ' + recurrentTime + ' days' : '-'}</div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={'mt-1 pe-1'}>
                        <div>
                          <label htmlFor="notes" className="labelModal mb-0">
                            Notes
                          </label>
                        </div>
                        <textarea
                          disabled={props.update && props.recurrent}
                          className="text-area-settings w-100"
                          defaultValue={notes ?? ''}
                          id="notes"
                          onChange={(event) => setNotes(event.target.value)}></textarea>
                      </div>
                    </div>
                    <div className={'mt-2 text-center'}>
                      {(!props.update || (props.update && !props.recurrent)) && (
                        <Button id={'addProduct'} onClick={() => addButton()} value={props.update ? 'Update' : 'Add'} />
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default AddIncomesExpensesModal;
