import httpClient from "../utility/httpClient";

export interface DashboardInterface {
    inventory: Inventory;
}

export interface Inventory {
    realTime: RealTime;
    allTime: AllTime;
}


export interface AllTime {
    payoutsAmount: PayoutsAmount;
    retailPrice: PayoutsAmount;
    otherExpenses: any;
    otherIncome: any;
    soldPairs: number;
    purchasedPairs: number;
    balance: any;
    netProfit: any;
}

export interface PayoutsAmount {
    value: number;
    currency: string;
}

export interface RealTime {
    inventoryValue: any;
    retailPrice: PayoutsAmount;
    inventoryItems: number;
    estimatedProfit: any;
}
export default class DashboardService {

    getDashInfoUrl = "/api/v1/dash/";

    public async getDashInfo(dateFilter?: any): Promise<DashboardInterface> {
        let id = 'USERIDAUTH0'; //:invIdId
        let params = '';
        if (dateFilter?.length > 0) {
            if (dateFilter[0]) {
                params = "?startDate=" + dateFilter[0];
            }
            if (dateFilter[0] && dateFilter[1]) {
                params = "?startDate=" + dateFilter[0] + "&endDate=" + dateFilter[1];
            }
        }
        let url = this.getDashInfoUrl + id + params;
        return httpClient.get(url).then(e => e.data);
    }

}