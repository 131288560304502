import React, { useCallback, useEffect, useMemo, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import './Inventory.scss';
import Filters from '../../../common/filters/Filters';
import RowFilters from '../../../common/rowFilters/rowFilters';
import FiltersUtils, { FiltersInterface as SortFiltersInterface } from '../../../../utility/filtersUtils';
import RowItem from '../../../common/rowItem/rowItem';
import AddModal_Inventory from '../../../modals/AddModal_Inventory/AddModal_Inventory';
import InventoryListService from '../../../../services/inventory/InventoryListService';
import { InventoryListInterface, InventoryResultInterface } from '../../../../services/InventoryInterfaces';
import Loader from '../../../common/loader/Loader';
import { InventoryFilters } from '../../../../services/inventory/InventoryFilterInterfaces';
import { PaginationFiltersInterface } from '../../../../services/CommonInterfaces';
import PaginationFilters from '../../../common/paginationFilters/PaginationFilters';
import { checkIfArrayAreEquals } from '../../../../utility/commonUtils';
import WarningModal from '../../../modals/WarningModal/WarningModal';
import LazyImage from '../../../common/lazyimage/LazyImage';
import ToastHandler from '../../../../utility/ToastHandler/ToastHandler';

const Inventory: React.FC = () => {
  //Service
  const inventoryService = useMemo(() => {
    let service = new InventoryListService();
    service.MAX_ELEMENTS = FiltersUtils.initializeItemPerPage();
    return service;
  }, []);

  const [MAX_ELEMENTS, setMaxElements] = useState<number>(FiltersUtils.initializeItemPerPage());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<InventoryFilters>();
  const [sortFilters, setSortFilters] = useState<Array<SortFiltersInterface>>(FiltersUtils.initializeFilters('inventory'));
  const [paginationFilters, setPagination] = useState<PaginationFiltersInterface>({ page: 0, count: undefined });
  const [list, setList] = useState<InventoryListInterface>();
  const [listItems, setListItems] = useState<Array<InventoryResultInterface>>([]);

  const [checkboxAll, setCheckboxAll] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<{ show: boolean; message: string; args: any; isLoading: boolean }>({
    show: false,
    message: '',
    args: {},
    isLoading: false,
  });

  const [selectedTasks, setSelectedTasks] = useState<Array<string>>([]);

  const [hideInfo, setHideInfo] = useState(false);

  const getList = useCallback(
    async (sortFilters?: SortFiltersInterface[], filters?: InventoryFilters, paginationFilters?: PaginationFiltersInterface) => {
      setIsLoading(true);
      return await inventoryService.getProducts(sortFilters, filters, paginationFilters).then((_list) => {
        setSelectedTasks([...[]]);
        setList(_list);
        setPagination((prev) => {
          return { ...prev, count: _list.itemsCount };
        });
        setListItems(_list.results ? [..._list.results] : []);
        setIsLoading(false);
      });
    },
    [inventoryService]
  );

  useEffect(() => {
    getList(sortFilters, filters, paginationFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getList, sortFilters, filters, paginationFilters.page]);

  useEffect(() => {
    setPagination((prev) => {
      return { ...prev, page: 0 };
    });
  }, [filters]);

  const refreshList = useCallback(async () => {
    await getList(sortFilters, filters, paginationFilters);
  }, [filters, getList, paginationFilters, sortFilters]);

  const handleCheckbox = () => {
    setCheckboxAll(!checkboxAll);
    setTaskSelection(null, true);
  };

  const handleFilters = (filter: any) => {
    if (filter) {
      setFilters(filter);
      return;
    }
    setFilters(undefined);
  };

  const handlePaginationFilter = (pagFilter: number) => {
    if (pagFilter !== undefined) {
      setPagination((prev) => {
        return { ...prev, page: pagFilter };
      });
      return;
    }
  };

  const handleSortFilter = (filter?: string) => {
    if (!filter) {
      setSortFilters([...FiltersUtils.initializeFilters('inventory')]);
      return;
    }
    if (filter === 'hideInfo') {
      setHideInfo((prev) => {
        return !prev;
      });
      return;
    }
    if (sortFilters) {
      let filterToUpdate = sortFilters.find((e) => e.name === filter);
      if (filterToUpdate) {
        filterToUpdate.value = !filterToUpdate!.value;
        filterToUpdate.lastSelect = true;
        sortFilters.forEach((e) => {
          if (e.name !== filterToUpdate!.name) {
            e.value = false;
            e.lastSelect = false;
          }
        });
        localStorage.setItem('filtersInventory', JSON.stringify(sortFilters));
        setSortFilters([...sortFilters]);
      }
      return;
    }
    setSortFilters([]);
  };

  const openModal = (bool?: boolean) => {
    setShowAddModal(bool || !showAddModal);
  };

  const deleteTask = (itemId?: string, confirmed?: boolean) => {
    if (!confirmed) {
      setWarningModal({
        message:
          itemId || selectedTasks.length === 1
            ? 'Are you sure you want to delete this item?'
            : 'Are you sure you want to delete ' + selectedTasks.length + '  items?',
        show: true,
        args: { selectedItem: selectedTasks.length === 1 ? listItems.find((e) => e.Id === (itemId || selectedTasks[0])) : null, params: itemId },
        isLoading: false,
      });
      return;
    }
    inventoryService.deletePurchase(itemId ? [itemId] : selectedTasks).then((e) => {
      setWarningModal((prev) => ({ show: false, message: prev.message, args: prev.args, isLoading: false }));
      setIsLoading(true);
      selectedTasks.length > 0 && setSelectedTasks([]);
      refreshList();
    });
  };

  const setItemPerPage = (pages: number) => {
    inventoryService.MAX_ELEMENTS = pages;
    localStorage.setItem('itemPerPage', pages.toString());
    setMaxElements(pages);
    setPagination((prev) => {
      return { ...prev, page: 0 };
    });
    refreshList();
  };

  const setTaskSelection = (v: any, all?: boolean) => {
    if (all) {
      setSelectedTasks((prev) => {
        if (
          checkIfArrayAreEquals(
            prev,
            listItems.map((e) => e.Id)
          )
        ) {
          return [];
        } else {
          return [...listItems.map((e) => e.Id)];
        }
      });
    } else {
      setSelectedTasks((prev) => {
        if (prev.includes(v.Id)) {
          return [...prev.filter((e) => e !== v.Id)];
        } else {
          return [...prev, v.Id];
        }
      });
    }
  };

  const closeModal = (str: string, didChange?: boolean) => {
    switch (str) {
      case 'warning':
        if (didChange) {
          setWarningModal((prev) => ({ ...prev, isLoading: true }));
          deleteTask(warningModal?.args?.selectedItem?.Id, true);
        } else {
          setWarningModal((prev) => ({ show: false, message: prev.message, args: prev.args, isLoading: false }));
        }
        break;
      default:
        break;
    }
  };

  const exportInventory = async () => {
    return await inventoryService
      .export(selectedTasks, filters)
      .then((_list) => {
        ToastHandler.dispatchToast({ link: _list }, 'shareExport');
      })
      .catch((e) => {
        ToastHandler.customToast({ message: 'Error during exporting inventory, retry or contact assistance' });
      });
  };

  return (
    <>
      <div className="inventory">
        <Filters
          type={'inventory'}
          export={() => exportInventory()}
          openModal={() => openModal()}
          onChange={(args) => {
            handleFilters(args);
          }}
          showDeleteSelectedTasks={selectedTasks.length > 0}
          deleteSelectedTasks={() => deleteTask()}
        />
        <RowFilters
          hideInfo={hideInfo}
          filters={sortFilters}
          selected={
            listItems?.length > 0
              ? checkIfArrayAreEquals(
                  selectedTasks,
                  listItems.map((e) => e.Id)
                )
              : false
          }
          handleCheckbox={() => handleCheckbox()}
          handleFilterClick={(str) => handleSortFilter(str)}
        />
        {isLoading && (
          <div className="inventoryContainer d-flex justify-content-center align-items-center w-100">
            {<Loader bootstrapLoader customStyle={{ width: '36px', height: '36px', marginTop: '50px' }} />}
          </div>
        )}
        {!isLoading && listItems?.length > 0 && (
          <div className="inventoryList">
            {listItems.map((v, i) => (
              <RowItem
                hideInfo={hideInfo}
                selected={selectedTasks.includes(v.Id)}
                onSelect={() => {
                  setTaskSelection(v);
                }}
                refresh={() => refreshList()}
                delete={() => deleteTask(v.Id, true)}
                key={i}
                filters={sortFilters}
                type="purchase"
                item={v}
              />
            ))}
          </div>
        )}
        {!isLoading && !list?.results && (
          <div className="inventoryContainer d-flex justify-content-center align-items-center w-100">
            {
              <span className=" listMessage" onClick={() => openModal()}>
                Your Inventory is clear, <span className="text-decoration-underline cursor-pointer">Add a Product</span>
              </span>
            }
          </div>
        )}
        {!isLoading && <div className="divider" />}
        {!isLoading && (
          <PaginationFilters
            count={paginationFilters.count}
            selected={paginationFilters.page!}
            itemPerPage={MAX_ELEMENTS}
            updateElements={(pages) => {
              setItemPerPage(pages);
            }}
            onChange={(res) => {
              handlePaginationFilter(res);
            }}
          />
        )}
      </div>

      <AddModal_Inventory show={showAddModal} onHide={() => openModal(false)} onSubmit={() => refreshList()} />
      {
        <WarningModal
          isLoading={warningModal.isLoading}
          show={warningModal.show}
          onHide={(didChange?: boolean) => {
            closeModal('warning', didChange);
          }}>
          <div className="text-center">
            <div>{warningModal.message}</div>
            {warningModal.args.selectedItem && (
              <div className="d-flex align-items-center justify-content-start mt-2 w-100">
                <LazyImage customClass="image" src={warningModal.args.selectedItem?.item?.imgUrl} alt="Item" />
                <div className="ps-1 w-100 d-flex flex-column align-items-start justify-content-center" style={{ fontWeight: 600 }}>
                  <div className={'w-100 text-center'}>{warningModal.args?.selectedItem?.item?.name}</div>
                  <div className={'w-100 text-center'}>{warningModal.args?.selectedItem?.item?.sku}</div>
                </div>
              </div>
            )}
          </div>
        </WarningModal>
      }
    </>
  );
};

export default Inventory;
