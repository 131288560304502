import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardInterface } from '../utils/card_shower';
import './Card.scss';
import UserDataStorage from '../../../../../singletonStorage/userDataStorage';
import { removeDuplicatesFromArray } from '../../../../../utility/commonUtils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomModal from '../../../../modals/CustomModal/CustomModal';

interface CardProps {
  card: CardInterface;
}

const Card: React.FC<CardProps> = ({ card }: CardProps) => {
  const { t } = useTranslation('dashboard');
  const userData: UserDataStorage = UserDataStorage.getInstance();
  const [showModal, setShowModal] = useState<{ show: boolean; card?: CardInterface }>({ show: false, card: undefined });

  // Rileva se il dispositivo è mobile
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="card">
      {card.description && (
        <span className="tooltipInfoCard">
          {!isMobile ? (
            <OverlayTrigger overlay={<Tooltip>{card.description}</Tooltip>}>
              <i className="fa-solid fa-info-circle"></i>
            </OverlayTrigger>
          ) : (
            <i className="fa-solid fa-info-circle" onClick={() => setShowModal({ show: true, card: card })}></i>
          )}
        </span>
      )}
      <span className="cardIcon">
        <i className={card.fa_icon}></i>
      </span>
      <div>
        <div className="cardValue">
          {card.format && card.value.includes(' ')
            ? removeDuplicatesFromArray(
                (card.value.split(' ')[0] + ' ' + userData.settingsStorage!['currency']).split(userData.settingsStorage!['currency'])
              ).join(userData.settingsStorage!['currency'])
            : removeDuplicatesFromArray(card.value.toString().split(userData.settingsStorage!['currency'])).join(userData.settingsStorage!['currency'])}
        </div>
        <div className="cardName">{t(card.name)}</div>
      </div>

      {
        <CustomModal show={showModal.show} onHide={() => setShowModal({ card: undefined, show: false })}>
          <div className="card">
            <span className="cardIcon">
              <i className={card.fa_icon}></i>
            </span>
            <div>
              <div className="cardValue">
                {card.format && card.value.includes(' ')
                  ? removeDuplicatesFromArray(
                      (card.value.split(' ')[0] + ' ' + userData.settingsStorage!['currency']).split(userData.settingsStorage!['currency'])
                    ).join(userData.settingsStorage!['currency'])
                  : removeDuplicatesFromArray(card.value.toString().split(userData.settingsStorage!['currency'])).join(userData.settingsStorage!['currency'])}
              </div>
              <div className="cardName">{t(card.name)}</div>
            </div>
          </div>
          <div className="text-center text-white">
            <div className="p-4">{card.description}</div>
          </div>
        </CustomModal>
      }
    </div>
  );
};

export default Card;
