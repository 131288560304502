import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import '../CommonModal.scss';
import './AddModal_WTB.scss';
import AutoComplete from "../../common/autocomplete/AutoComplete";
import { SearchProductsInterface } from "../../../services/CommonInterfaces";
import Button from "../../common/button/Button";
import Logo from "../../../assets/images/logo/transparent-logo.png";
import UserDataStorage from "../../../singletonStorage/userDataStorage";
import ToastHandler from "../../../utility/ToastHandler/ToastHandler";
import WTBService from "../../../services/wtbs/WtbsListService";
import SizePicker_WTB from "../../common/sizepicker_WTB/SizePicker_WTB";
import { uniqueId } from "lodash";
import ScannerService from "../../../services/scanner/ScannerService";
import LazyImage from "../../common/lazyimage/LazyImage";
import ScannerComponent from "../../common/scannerComponent/scannerComponent";
const _ = require('lodash');

interface AddModalProps {
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
}

const AddModal_WTB: React.FC<AddModalProps> = (props) => {

    //Service
    const wtbService = new WTBService();
    const scannerService = new ScannerService();
    const userData = UserDataStorage.getInstance();

    const [selectedItem, setSelectedItem] = useState<SearchProductsInterface & { itemQty?: any }>();
    const [showWarningClose, setShowWarningClose] = useState<boolean>(false);

    // Scanner Section
    const [showScanner, setShowScanner] = useState<boolean>(false);
    const [scannedCodes, setScannedCodes] = useState<Array<any>>([]);
    const [scannedItems, setScannedItems] = useState<Array<any>>([]);

    const [notes, setNotes] = useState<string>();
    const [pickedSize, setPickedSize] = useState<Map<string, { qty: number, price: number, currency: string }>>();

    const [errors, setErrors] = useState({
        pickedSize: ""
    })


    const handleClose = (forceClose?: boolean, resetForm?: boolean) => {
        if (forceClose || resetForm) {
            if (resetForm) {
                setNotes("");
                setPickedSize(new Map());
            } else {
                setNotes("");
                setPickedSize(new Map());
                setSelectedItem(undefined);
                setShowWarningClose(false);
                props.onHide();
            }
            return;
        } else {
            setShowWarningClose(true);
            return;
        }
    }

    const addButton = async () => {
        if (!(pickedSize?.size !== undefined && pickedSize?.size > 0)) {
            setErrors(() => {
                return {
                    pickedSize: !(pickedSize?.size !== undefined && pickedSize?.size > 0) ? "Select a size before submitting" : ""
                }
            }
            );
            return;
        }
        if (pickedSize?.size !== undefined && pickedSize?.size > 0) {
            setErrors({ pickedSize: "" });
            const obj = {
                itemId: selectedItem?.sku,
                "variants": Object.fromEntries(pickedSize),
                "notes": notes
            }
            await wtbService.addItemMultisize(obj).then(() => {
                ToastHandler.customToast({ message: "Item Added Successfully" });
                if (scannedItems.length > 1) {
                    changeItem(1, true);
                } else {
                    props.onSubmit();
                    handleClose(true);
                }
            });
        }
    }

    let isScanning = false;
    const processResult = (result: any) => {
        if (result && !isScanning) {
            isScanning = true;
            scannerService.searchScannedProducts(result).then((e: any) => {
                if (e?.item && e?.item !== null && e?.variant && e?.variant !== null) {
                    let obj: any = e;
                    obj.id = uniqueId();
                    obj.code = result;
                    setScannedCodes((prev) => [...prev, e]);
                    setShowScanner(false);
                    isScanning = false;
                } else {
                    ToastHandler.customToast({ message: "Item " + result + " not found" });
                    setShowScanner(false);
                    isScanning = false;
                }
            }).catch(() => {
                ToastHandler.customToast({ message: "Item " + result + " not found" });
                setShowScanner(false);
                isScanning = false;
            })
        }
    }

    const debounceFn = _.debounce(processResult, 10000, { leading: true });

    const processAllResults = () => {
        let codes = scannedCodes.map(e => e.code);
        codes = ["195241511627", "195238867713"]
        scannerService.searchAllScannedProducts(codes).then((e: Array<any>) => {
            setScannedItems(e);
            let selectedItem = e[0].item;
            selectedItem.itemQty = e[0].qty;
            Object.keys(selectedItem.itemQty).forEach(key => {
                selectedItem.itemQty[key] = {
                    qty: selectedItem.itemQty[key],
                    price: 0,
                    currency: userData.settingsStorage!.sizeCountry.toUpperCase()
                };
            });
            setPickedSize(new Map(Object.entries(selectedItem.itemQty)));
            setSelectedItem(selectedItem);
        })
    }

    const changeItem = (action: number, deleteItem = false) => {
        let currentIndex = [...scannedItems].findIndex(e => selectedItem?.id === e.item.id);
        let newIndex;
        let scannedItemsLength = scannedItems.length;
        if (action === 1) {
            newIndex = (currentIndex + 1) % scannedItemsLength;
        } else {
            newIndex = (currentIndex - 1 + scannedItemsLength) % scannedItemsLength;
        }
        let newSelectedItem = scannedItems[newIndex].item;
        newSelectedItem.itemQty = scannedItems[newIndex].qty;
        if (deleteItem) {
            setScannedItems((_prev) => {
                return [..._prev.splice(currentIndex, 1)]
            })
        }
        handleClose(false, true);
        Object.keys(newSelectedItem.itemQty).forEach(key => {
            newSelectedItem.itemQty[key] = {
                qty: newSelectedItem.itemQty[key],
                price: 0,
                currency: userData.settingsStorage!.sizeCountry.toUpperCase()
            };
        });
        setPickedSize(new Map(Object.entries(newSelectedItem.itemQty)));
        setSelectedItem(newSelectedItem);
    }


    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName={"commonModal"}
            backdropClassName={"commonModalBackdrop"}
            dialogClassName={"commonModalDialog"}
        >
            <Modal.Body>
                <>
                    <>
                        {showWarningClose && <div className={"closeConfirm"}>
                            <div className={"w-100 h-100 p-1 color-white d-flex flex-column justify-content-center align-items-center"}>
                                <div className={"w-100 p-2 mb-2 text-center message"}>You have pending changes, are you sure you want to close?</div>
                                <div className={"d-flex justify-content-center align-items-center"}>
                                    <Button customClass={"closeConfirmButtons btn-dark"} id={"discard"} onClick={() => setShowWarningClose(false)} value={"Discard"} />
                                    <Button customClass={"closeConfirmButtons bg-danger btn-danger"} id={"close"} onClick={() => handleClose(true)} value={"Close"} />
                                </div>
                            </div>
                        </div>}
                        <div className={`addModal ${showWarningClose ? "blurred" : ""}`}>
                            <div className={'containerModal'}>
                                <div className={"closeButton"} onClick={() => handleClose()}>
                                    <i className="fa-solid fa-xmark"></i>
                                </div>
                                <div className={"w-100 h6 text-center color-white"}>Add New Item</div>
                                <div className={"mt-2 ps-2 pe-2 pt-4 pb-2 color-white"}>
                                    {!selectedItem &&
                                        <>
                                            {scannedCodes?.length === 0 &&
                                                <div>
                                                    <div>Product</div>
                                                    <AutoComplete onSelect={(item) => setSelectedItem(item)} />
                                                </div>
                                            }
                                            {scannedCodes?.length > 0 &&
                                                <div>
                                                    <div>Scanned Items</div>
                                                    <div className="d-flex flex-column justify-content-start mt-2 scannedItemsList">
                                                        {
                                                            scannedCodes.map(e =>
                                                                <div className="item d-flex justify-content-start align-items-center" key={e.id}>
                                                                    <i className="fa-solid fa-trash ps-2 pe-2 cursor-pointer" style={{ fontSize: 16 }} onClick={() => {
                                                                        setScannedCodes((prev) => prev.filter(_e => _e.id !== e.id))
                                                                    }}></i>
                                                                    <LazyImage customClass="image" src={e.item.imgUrl} alt="Item" />
                                                                    {e.item.name + " " + e.item.id + " - " + e.variant.size[userData.settingsStorage!.sizeCountry.toUpperCase()]}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className={"d-flex justify-content-end align-items-center mt-2"}>
                                                <Button id={"addProduct"}
                                                    customClass="ps-3 pe-3"
                                                    onClick={() => setShowScanner(true)}
                                                    children={<span>Scan {scannedCodes.length > 0 && 'Other '}<i className="fa-solid fa-camera ps-1"></i> </span>}
                                                />
                                                {(scannedCodes.length > 0 || true) && <Button id={"addProduct"}
                                                    customClass="ps-3 pe-3 ms-2"
                                                    onClick={() => processAllResults()}
                                                    children={<span>Proceed <i className="fa-solid fa-arrow-right"></i></span>}
                                                />}
                                            </div>
                                        </>
                                    }
                                    {selectedItem &&
                                        <>
                                            <div className={"w-100 h-100"} key={selectedItem.id}>
                                                <div className={"row gx-0 w-100"}>
                                                    <div className={"col-12 col-sm-5"}>
                                                        <div className="productDetailWTB">
                                                            <img className="p-1 rounded-3" src={selectedItem.imgUrl}
                                                                onError={({ currentTarget }) => {
                                                                    try { currentTarget.onerror = null; currentTarget.src = Logo; } catch (e) { }
                                                                }}
                                                                width={80} height={80} alt={selectedItem.name} />
                                                            <div className="d-flex flex-column justify-content-center align-items-start ps-1 pe-1">
                                                                <div className={"brand"}>{selectedItem.brandId}</div>
                                                                <div className={"name"}>{selectedItem.name}</div>
                                                                <div className={"sku"}>{selectedItem.sku}</div>
                                                            </div>
                                                        </div>
                                                        <div className={"mt-3 w-100"}>
                                                            <div>
                                                                <label htmlFor="notes" className="labelModal mb-0">Notes</label>
                                                            </div>
                                                            <textarea className="text-area-settings w-100" id="notes" onChange={(event) => setNotes(event.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                    <SizePicker_WTB variants={selectedItem.variants} qty={selectedItem.itemQty} onChange={(e) => setPickedSize(e)} />
                                                </div>
                                                <div className={"mt-5 text-center"}>
                                                    {errors.pickedSize && <div className={"inputError text-danger mb-2"}>{errors.pickedSize}</div>}
                                                    {
                                                        <div className="mt-2 w-100 d-flex justify-content-around align-items-center">
                                                            {[...scannedItems].length > 1 && <Button id={"prevProduct"}
                                                                customClass="ps-3 pe-3"
                                                                onClick={() => { changeItem(0) }}
                                                                children={<span>Previous Item <i className="fa-solid fa-arrow-left"></i> </span>}
                                                            />}
                                                            <Button id={"addProduct"} onClick={() => addButton()} value={"Add Item"} />
                                                            {[...scannedItems].length > 1 && <Button id={"prevProduct"}
                                                                customClass="ps-3 pe-3"
                                                                onClick={() => { changeItem(1) }}
                                                                children={<span>Next Item <i className="fa-solid fa-arrow-right"></i> </span>}
                                                            />}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                </>
            </Modal.Body>

            <Modal
                {...props}
                aria-labelledby="scanner-contained-modal-title-vcenter"
                centered
                show={showScanner} onHide={() => setShowScanner(false)}
                contentClassName={"commonModal"}
                backdropClassName={"commonModalBackdrop"}
                dialogClassName={"commonModalDialog"}
            >
                <Modal.Body>
                    <>
                        {<ScannerComponent
                            key='scannerModal'
                            fps={10}
                            qrbox={300}
                            disableFlip={false}
                            qrCodeSuccessCallback={(result: any) => { debounceFn(result) }}
                        />}
                    </>
                </Modal.Body>
            </Modal>

        </Modal>
    );
}

export default AddModal_WTB;