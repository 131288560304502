import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './sidebar.scss';
import SideBarNavigator, { Navigator } from './utils/sidebar-navigator';
import UserDataStorage from '../../../singletonStorage/userDataStorage';
import { useWindowSize } from '../../../utility/customHooks';

interface SidebarProps {
  noAccess?: boolean;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const userData: UserDataStorage = UserDataStorage.getInstance();

  const [width] = useWindowSize();

  const navigate = useNavigate();

  const navigator: SideBarNavigator = new SideBarNavigator();

  const [buttons, setButtons] = useState<Array<JSX.Element>>([]);

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    setButtons(
      navigator.getNavigation
        .filter((e) => {
          if (e.hide) {
            return undefined;
          }
          if (props.noAccess && e.needLogin) {
            return undefined;
          }
          if (!props.noAccess && !e.needLogin) {
            return undefined;
          }
          if (!userData.sessionStorage?.roles && e.roleGuard) {
            return undefined;
          }
          if (userData.sessionStorage?.roles && e.roleGuard) {
            const contains = userData.sessionStorage?.roles.some((_e: string) => {
              return e.roleGuard!.includes(_e);
            });
            if (contains) {
              return e;
            } else {
              return undefined;
            }
          }
          return e;
        })
        .map((e: Navigator) => Icon(e))
    );
  }, [userData]);

  return (
    <div className="sidebar">
      {width > 768 && (
        <div className="logoContainer">
          <div className="logo"></div>
          <span className="text-center">StepUp {process.env.REACT_APP_ENV !== 'prod' && 'DEV'}</span>
        </div>
      )}
      <div className="sidebar-content">
        <div className={'d-flex ' + (width <= 768 ? 'sidebar-row row' : 'flex-column mt-3')}>
          {buttons}
          {props.noAccess && localStorage?.getItem('UserJWT') && width <= 768 && (
            <div
              className={'sidebarButton wordButton'}
              key={'return'}
              onClick={() => {
                setTimeout(() => {
                  handleGoBack();
                });
              }}>
              <div className="position-relative d-flex align-items-center">
                <i className={'fa-regular fa-arrow-left'} /> <span className="ms-1">Go back to StepUp</span>
              </div>
            </div>
          )}
          <div
            className={'sidebarButton'}
            key={'discord'}
            onClick={() => {
              setTimeout(() => {
                window.open('https://discord.gg/nGHvs2NBcc', '_blank');
              });
            }}>
            <div className="position-relative">
              <i className={'fa-brands fa-discord'} />
            </div>
          </div>
          {props.noAccess && (
            <div
              className={'sidebarButton'}
              key={'x_social'}
              onClick={() => {
                setTimeout(() => {
                  window.open('https://x.com/Step__Up_', '_blank');
                });
              }}>
              <div className="position-relative">
                <i className={'fa-brands fa-x'} />
              </div>
            </div>
          )}
          {props.noAccess && (
            <div
              className={'sidebarButton'}
              key={'instagram'}
              onClick={() => {
                setTimeout(() => {
                  window.open('https://instagram.com/_stepupofficial_', '_blank');
                });
              }}>
              <div className="position-relative">
                <i className={'fa-brands fa-instagram'} />
              </div>
            </div>
          )}
          {!props.noAccess && (
            <div
              className={'sidebarButton'}
              key={'helper'}
              onClick={() => {
                setTimeout(() => {
                  window.open('https://stepup-1.gitbook.io/stepup/', '_blank');
                });
              }}>
              <div className="position-relative">
                <i className={'fa-solid fa-circle-info'} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function Icon(navigator: Navigator) {
  return (
    <NavLink
      className={({ isActive }) => {
        return 'sidebarButton ' + (isActive ? 'selected' : '') + (navigator.disabled ? 'disabled' : '');
      }}
      to={navigator.disabled ? window.location.href : navigator.link}
      key={navigator.name}
      onClick={(e) => {
        navigator.disabled && e.preventDefault();
      }}>
      <div className="position-relative">
        {navigator.disabled && <i className="fa-solid fa-slash disabledSidebarOption"></i>}
        <i className={navigator.fa_icon + (navigator.disabled ? ' disabled' : '')} />
      </div>
    </NavLink>
  );
}

export default Sidebar;
