import httpClient from "../../utility/httpClient"
import {InventoryListInterface} from "../InventoryInterfaces";
import {FiltersInterface} from "../../utility/filtersUtils";
import { InventoryFilters as InventoryFiltersInterface } from "./AdminInventoryFilterInterfaces";
import { PaginationFiltersInterface } from "../CommonInterfaces";

export default class AdminInventoryListService {

    MAX_ELEMENTS: number = 15;

    searchUrl = "/c3RlcHVwYWRtaW4/v1/inventory/all";

    public async getProducts(sortFilters?: FiltersInterface[], filters?: InventoryFiltersInterface, paginationFilter?: PaginationFiltersInterface): Promise<InventoryListInterface> {

        let orderBy = (sortFilters?.find(e=>e.lastSelect)!.value ? "+": "-") + (sortFilters?.find(e=>e.lastSelect)!.name);

        let path = "/filterPurchases";
        let page = "?page="+ (paginationFilter?.page ? paginationFilter?.page + 1 : "1") + "&";
        let sizePage = "itemperpage=" + this.MAX_ELEMENTS;
        let body = {
            "Name": filters?.searchFilters || "",
            "PurchaseDate": filters?.purDateFilter || [],
            "ShippingDate": [],
            "SaleDate": [],
            "Slug": "admin_inventory",
            "Sizes": filters?.sizeFilter || [],
            "PurchaseStore": filters?.purStoreFilter || [],
            "ListedOn": [],
            "OrderBy": orderBy,
            "With": "purchase.item,purchase.variant,variant.size,purchase.listings,purchase.sale"
        };
        let url = this.searchUrl + path + page + sizePage;
        return httpClient.post(url, body).then((e: any)=> {
            return e.data
        });
    }

}