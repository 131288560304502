import React, {useEffect, useState} from 'react';
import './Checkbox.scss';

interface CheckboxProps {
    id: string;
    onClick: () => void;
    selected: boolean;
    customClass?: string;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {

    const onClick = () => {
        props.onClick();
    }

    useEffect(()=>{
    }, [props.selected])

    return (
        <div className={`containerCheck ${props.selected ? "active" : ""} ${props.customClass ?? ""}`} onClick={onClick}/>
    );
}


export default Checkbox;
