import { SearchProductsInterface } from "./CommonInterfaces";
import httpClient from "../utility/httpClient";

export default class AddProductService {

    searchUrl = "/api/v1/items/";

    public async searchProducts(name: string): Promise<Array<SearchProductsInterface>> {
        let id = 'USERIDAUTH0'
        let path = "/search";
        let product = "?q=" + name;
        let params = "&with=item.variants,variant.size";
        let url = this.searchUrl + id + path + product + params;
        return httpClient.get(url).then(e => e.data);
    }
}