import React from 'react';
import './ListingsSection.scss';
import {InventoryResultInterface} from "../../../../../services/InventoryInterfaces";
import {schemaErrorsViewOrEditModal} from "../../ViewOrEditItemModal";

const ListingSectionsTabs = {
    listed: [
        {
            name: 'Site',
            col: 2,
            value: ""
        },
        {
            name: 'Consigned',
            col: 2,
            value: ""
        },
        {
            name: 'Your Price',
            col: 2,
            value: "130,00€"
        },
        {
            name: 'Your Payout',
            col: 2,
            value: "130,00€"
        },
        {
            name: 'Lowest Ask',
            col: 2,
            value: "130,00€"
        },
        {
            name: 'Possible Payout',
            col: 2,
            value: "130,00€"
        }
    ],
    unlisted: [
        {
            name: 'Site',
            col: 6,
            value: "Solebox"
        },
        {
            name: 'Lowest Ask',
            col: 3,
            value: "110,00€"
        },
        {
            name: 'Possible Payout',
            col: 3
        },
    ]
}

interface ListingSectionProps {
    item?: InventoryResultInterface,
    locked: boolean,
    onChange: (args: Record<any, any>) => void;
    error?: typeof schemaErrorsViewOrEditModal["listings"];
}

const ListingSection: React.FC<ListingSectionProps> = (props) => {

    return (
        <>
            <div>
                {
                    props.item && <>
                        <div className={"mt-3 pt-3"}>
                            <div className={"mt-2 mb-2 h5 text-left"}>
                                LISTED
                            </div>
                            <div className={"w-100 listingItemName"}>
                                <div className={"row mt-3 ps-1 pe-1"}>
                                    {ListingSectionsTabs.listed.map((e,i)=><div key={i} className={"col-"+e.col+" p-1 ps-2"}>{e.name}</div>)}
                                </div>
                                <div className={"row mt-1 listingItemRow"}>
                                    {ListingSectionsTabs.listed.map((e,i)=><div key={i} className={"col-"+e.col+" p-1 ps-2"}>{e.value}</div>)}
                                </div>
                                <div className={"row mt-1 listingItemRow"}>
                                    {ListingSectionsTabs.listed.map((e,i)=><div key={i} className={"col-"+e.col+" p-1 ps-2"}>{e.value}</div>)}
                                </div>
                                <div className={"row mt-1 listingItemRow"}>
                                    {ListingSectionsTabs.listed.map((e,i)=><div key={i} className={"col-"+e.col+" p-1 ps-2"}>{e.value}</div>)}
                                </div>
                            </div>
                            <div className={"mt-3 mb-2 h5 text-left"}>
                                UNLISTED
                            </div>
                            <div className={"w-100 listingItemName"}>
                                <div className={"row mt-3 ps-1 pe-1"}>
                                    {ListingSectionsTabs.unlisted.map((e,i)=><div key={i} className={"col-"+e.col+" p-1 ps-2"}>{e.name}</div>)}
                                </div>
                                <div className={"row mt-1 listingItemRow"}>
                                    {ListingSectionsTabs.unlisted.map((e,i)=><div key={i} className={"col-"+e.col+" p-1 ps-2"}>{e.value}</div>)}
                                </div>
                                <div className={"row mt-1 listingItemRow"}>
                                    {ListingSectionsTabs.unlisted.map((e,i)=><div key={i} className={"col-"+e.col+" p-1 ps-2"}>{e.value}</div>)}
                                </div>
                            </div>
                        </div>
                    </>
                }
                {!props.item && <>
                    <div className={"mt-4 pt-4 mb-4 pb-4 color-white text-center"}>
                        <div className={"mt-4 pt-4 mb-2 pb-2"}><i className="fa-solid fa-face-dizzy h1"></i></div>
                        <div>Uh Oh! No Listings Found for this item</div>
                    </div>
                    </>
                }
            </div>
        </>
    );
}


export default ListingSection;
