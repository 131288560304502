import React, {CSSProperties, useEffect, useState} from 'react';
import './Button.scss';
import Loader from "../loader/Loader";

interface ButtonProps {
    id: string;
    onClick: () => void;
    customClass?: string;
    customStyle?: CSSProperties;
    value?: any;
    shouldLoad?: boolean;
    children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = (props) => {

    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(props.shouldLoad ?? false);
        if (props.shouldLoad) {
            setTimeout(()=>{
                setLoading(false);
            }, 5000)
        } else {
            setLoading(false);
        }
    }, [props.shouldLoad]);

    return (
        <>
            <button type="button"
                    className={`${props.customClass || ""} btn buttonCommon`}
                    style={props.customStyle ?? {}}
                    id={props.id}
                    disabled={isLoading}
                    onClick={() => {
                        props.onClick()
                    }}
            >
                {
                    isLoading ?
                        <Loader bootstrapLoader customStyle={{width: '20px', height: '20px', marginTop: '4px'}}/>
                   : props.value || props.children
                }
            </button>
        </>
    );
}


export default Button;
