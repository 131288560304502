import React, {useState} from 'react';
import ThemeHandler from "../../../utility/dynamicTheme";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import './AutoComplete.scss';
import AddProductService from "../../../services/AddProductService";
import {SearchProductsInterface} from "../../../services/CommonInterfaces";
import Logo from './../../../assets/images/logo/transparent-logo.png';
const _ = require('lodash');

interface AutoCompletePropsInterface {
    onSelect: (item: any) => any;
    customValues?: Array<any>;
    defaultValue?: string;
}

const AutoComplete: React.FC<AutoCompletePropsInterface> = (props) => {

    const addProductService = new AddProductService();

    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    const _loadSuggestions = (query: string, callback: any) => {
        addProductService.searchProducts(query)
          .then((resp: any) => {
              callback(resp)
          });
      };
      
    const loadSuggestions = _.debounce(_loadSuggestions, 250);

    const handleOnSelect = (item: any) => {
        props.onSelect(item);
    }

    const formatResult = (value: any) => {
        if (props.customValues) {
            let item = value.data;
            return (
                <div onClick={()=>{handleOnSelect(item); setMenuIsOpen(false)}} className="optionElement w-100 d-flex justify-content-start align-items-center cursor-pointer">
                    <div className="w-100 p-2">
                        <span style={{ display: 'block', textAlign: 'left', fontSize: 16 }}>{item.value}</span>
                    </div>
                </div>
            )
        }
        let item = value.data as SearchProductsInterface;
        return (
                <div onClick={()=>handleOnSelect(item)} className="optionElement w-100 d-flex justify-content-start align-items-center cursor-pointer">
                    <img className="p-1 rounded-3" src={item.imgUrl}
                         onError={({ currentTarget }) => {
                             try { currentTarget.onerror = null; currentTarget.src=Logo;}catch(e){}}}
                         width={50} height={50} alt={item.name}/>
                    <div className="w-100 ps-1">
                        <div className="marquee">
                            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
                        </div>
                        <span style={{ display: 'block', textAlign: 'left', fontSize: 12 }}>{item.sku}</span>
                    </div>
                </div>
        )
        }

    return (
        <>
            <div className="w-100">
            { !props.customValues ? <AsyncSelect
            styles={{
                menu: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: 'transparent',
                }),
                menuList: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: ThemeHandler.getGlobalColor('--main-color'),
                    color: ThemeHandler.getGlobalColor('--text-input-base-color'),
                    borderRadius: '8px',
                    borderColor: 'transparent'
                }),
                control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: ThemeHandler.getGlobalColor('--main-color'),
                color: ThemeHandler.getGlobalColor('--text-input-base-color'),
                border: '1px solid transparent',
                borderRadius: '8px',
                fontFamily: 'DM Sans, serif !important'
              }),
              noOptionsMessage: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: ThemeHandler.getGlobalColor('--main-color'),
                color: ThemeHandler.getGlobalColor('--text-input-base-color'),
                border: '1px solid transparent',
                borderRadius: '8px',
                fontFamily: 'DM Sans, serif !important'
              }),
              input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white'
                }),
                singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: 'white'
                }),
                }
            }
            components={{Option: formatResult}}
            noOptionsMessage={() => "No results found"}
            autoFocus={true}
            loadOptions={loadSuggestions}
            /> :
                <Select
                    styles={{
                        menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: 'transparent',
                        }),
                        menuList: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: ThemeHandler.getGlobalColor('--main-color'),
                            color: ThemeHandler.getGlobalColor('--text-input-base-color'),
                            borderRadius: '8px',
                            borderColor: 'transparent'
                        }),
                        control: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: ThemeHandler.getGlobalColor('--main-color'),
                            color: ThemeHandler.getGlobalColor('--text-input-base-color'),
                            border: '1px solid transparent',
                            borderRadius: '8px',
                            fontFamily: 'DM Sans, serif !important'
                        }),
                        noOptionsMessage: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: ThemeHandler.getGlobalColor('--main-color'),
                            color: ThemeHandler.getGlobalColor('--text-input-base-color'),
                            border: '1px solid transparent',
                            borderRadius: '8px',
                            fontFamily: 'DM Sans, serif !important'
                        }),
                        input: (baseStyles) => ({
                            ...baseStyles,
                            color: 'white'
                        }),
                        singleValue: (baseStyles) => ({
                            ...baseStyles,
                            color: 'white'
                        }),
                    }
                    }
                    components={{Option: formatResult}}
                    noOptionsMessage={() => "No results found"}
                    autoFocus={true}
                    options={props.customValues || []}
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={()=>setMenuIsOpen(true)}
                    value={props.defaultValue && {value: props.defaultValue, label: props.defaultValue}}
                />
            }
            </div>
        </>
    )
}

export default AutoComplete;