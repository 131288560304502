import React, { useCallback, useEffect, useMemo, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import './SharedInventory.scss';
import Filters from '../../../common/filters/Filters';
import RowFilters from '../../../common/rowFilters/rowFilters';
import FiltersUtils, { FiltersInterface as SortFiltersInterface } from '../../../../utility/filtersUtils';
import RowItem from '../../../common/rowItem/rowItem';
import InventoryListService from '../../../../services/inventory/InventoryListService';
import { InventoryListInterface, InventoryResultInterface } from '../../../../services/InventoryInterfaces';
import Loader from '../../../common/loader/Loader';
import { InventoryFilters } from '../../../../services/inventory/InventoryFilterInterfaces';
import { PaginationFiltersInterface } from '../../../../services/CommonInterfaces';
import PaginationFilters from '../../../common/paginationFilters/PaginationFilters';
import { checkIfArrayAreEquals } from '../../../../utility/commonUtils';
import { useLocation } from 'react-router-dom';
import UserDataStorage from '../../../../singletonStorage/userDataStorage';

const SharedInventory: React.FC = () => {
  //Service
  const inventoryService = useMemo(() => {
    let service = new InventoryListService();
    service.MAX_ELEMENTS = FiltersUtils.initializeItemPerPage();
    return service;
  }, []);

  const location = useLocation();

  const [MAX_ELEMENTS, setMaxElements] = useState<number>(FiltersUtils.initializeItemPerPage());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<InventoryFilters>();
  const [sortFilters, setSortFilters] = useState<Array<SortFiltersInterface>>(FiltersUtils.initializeFilters('sharedInventory'));
  const [paginationFilters, setPagination] = useState<PaginationFiltersInterface>({ page: 0, count: undefined });
  const [list, setList] = useState<InventoryListInterface>();
  const [listItems, setListItems] = useState<Array<InventoryResultInterface>>([]);

  const [checkboxAll, setCheckboxAll] = useState<boolean>(false);

  const [selectedTasks, setSelectedTasks] = useState<Array<string>>([]);

  const [hideInfo, setHideInfo] = useState(false);

  const getList = useCallback(
    async (sortFilters?: SortFiltersInterface[], filters?: InventoryFilters, paginationFilters?: PaginationFiltersInterface) => {
      setIsLoading(true);
      return await inventoryService.getSharedProducts(location.pathname, sortFilters, filters, paginationFilters).then((_list) => {
        setSelectedTasks([...[]]);
        setList(_list);
        setPagination((prev) => {
          return { ...prev, count: _list.itemsCount };
        });
        setListItems(_list.results ? [..._list.results] : []);
        setIsLoading(false);
      });
    },
    [inventoryService]
  );

  useEffect(() => {
    inventoryService.getSharedProductsSession(location.pathname).then((e) => {
      getList(sortFilters, filters, paginationFilters);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getList, sortFilters, filters, paginationFilters.page]);

  useEffect(() => {
    setPagination((prev) => {
      return { ...prev, page: 0 };
    });
  }, [filters]);

  const refreshList = useCallback(async () => {
    await getList(sortFilters, filters, paginationFilters);
  }, [filters, getList, paginationFilters, sortFilters]);

  const handleCheckbox = () => {
    setCheckboxAll(!checkboxAll);
    setTaskSelection(null, true);
  };

  const handleFilters = (filter: any) => {
    if (filter) {
      setFilters(filter);
      return;
    }
    setFilters(undefined);
  };

  const handlePaginationFilter = (pagFilter: number) => {
    if (pagFilter !== undefined) {
      setPagination((prev) => {
        return { ...prev, page: pagFilter };
      });
      return;
    }
  };

  const handleSortFilter = (filter?: string) => {
    if (!filter) {
      setSortFilters([...FiltersUtils.initializeFilters('sharedInventory')]);
      return;
    }
    if (filter === 'hideInfo') {
      setHideInfo((prev) => {
        return !prev;
      });
      return;
    }
    if (sortFilters) {
      let filterToUpdate = sortFilters.find((e) => e.name === filter);
      if (filterToUpdate) {
        filterToUpdate.value = !filterToUpdate!.value;
        filterToUpdate.lastSelect = true;
        sortFilters.forEach((e) => {
          if (e.name !== filterToUpdate!.name) {
            e.value = false;
            e.lastSelect = false;
          }
        });
        localStorage.setItem('filtersSharedInventory', JSON.stringify(sortFilters));
        setSortFilters([...sortFilters]);
      }
      return;
    }
    setSortFilters([]);
  };

  const setItemPerPage = (pages: number) => {
    inventoryService.MAX_ELEMENTS = pages;
    localStorage.setItem('itemPerPage', pages.toString());
    setMaxElements(pages);
    setPagination((prev) => {
      return { ...prev, page: 0 };
    });
    refreshList();
  };

  const setTaskSelection = (v: any, all?: boolean) => {
    if (all) {
      setSelectedTasks((prev) => {
        if (
          checkIfArrayAreEquals(
            prev,
            listItems.map((e) => e.Id)
          )
        ) {
          return [];
        } else {
          return [...listItems.map((e) => e.Id)];
        }
      });
    } else {
      setSelectedTasks((prev) => {
        if (prev.includes(v.Id)) {
          return [...prev.filter((e) => e !== v.Id)];
        } else {
          return [...prev, v.Id];
        }
      });
    }
  };

  return (
    <>
      <div className="inventory">
        <Filters
          type={'sharedInventory'}
          openModal={() => {}}
          onChange={(args) => {
            handleFilters(args);
          }}
          showDeleteSelectedTasks={selectedTasks.length > 0}
        />
        <RowFilters
          hideInfo={hideInfo}
          type="sharedInventory"
          filters={sortFilters}
          selected={
            listItems?.length > 0
              ? checkIfArrayAreEquals(
                  selectedTasks,
                  listItems.map((e) => e.Id)
                )
              : false
          }
          handleCheckbox={() => handleCheckbox()}
          handleFilterClick={(str) => handleSortFilter(str)}
        />
        {isLoading && (
          <div className="inventoryContainer d-flex justify-content-center align-items-center w-100">
            {<Loader bootstrapLoader customStyle={{ width: '36px', height: '36px', marginTop: '50px' }} />}
          </div>
        )}
        {!isLoading && listItems?.length > 0 && (
          <div className="inventoryList">
            {listItems.map((v, i) => (
              <RowItem
                hideInfo={hideInfo}
                selected={selectedTasks.includes(v.Id)}
                onSelect={() => {
                  setTaskSelection(v);
                }}
                refresh={() => refreshList()}
                key={i}
                filters={sortFilters}
                type="sharedInventory"
                item={v}
              />
            ))}
          </div>
        )}
        {!isLoading && !list?.results && (
          <div className="inventoryContainer d-flex justify-content-center align-items-center w-100">
            {<span className=" listMessage">This list is empty</span>}
          </div>
        )}
        {!isLoading && <div className="divider" />}
        {!isLoading && (
          <PaginationFilters
            count={paginationFilters.count}
            selected={paginationFilters.page!}
            itemPerPage={MAX_ELEMENTS}
            updateElements={(pages) => {
              setItemPerPage(pages);
            }}
            onChange={(res) => {
              handlePaginationFilter(res);
            }}
          />
        )}
      </div>
    </>
  );
};

export default SharedInventory;
