import React, { useEffect, useState } from 'react';
import Content from '../main/content/Content';
import Sidebar from '../main/sidebar/Sidebar';
import './Home.scss';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import SideBarNavigator from '../main/sidebar/utils/sidebar-navigator';

interface HomeProps {
  noAccess?: boolean;
}

const Home: React.FC<HomeProps> = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();

  const navigator: SideBarNavigator = new SideBarNavigator();

  useEffect(() => {
    const pathnames = navigator.getNavigation.map((e) => e.link);

    // Controlla se il path corrente corrisponde a una rotta statica o dinamica
    const isRouteValid = pathnames.some((path) => matchPath(path, location.pathname));

    if (!isRouteValid) {
      navigate('/');
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setWidth(window.innerWidth);
      }, 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={'w-100 h-100 d-flex ' + (width <= 768 ? 'flex-column-reverse' : '')}>
      {/*SIDEBAR*/ <Sidebar noAccess={props.noAccess} />}
      {/*LARGE SECTION - NEXT TO SIDEBAR*/ <Content noAccess={props.noAccess} />}
    </div>
  );
};

export default Home;
