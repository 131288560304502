import React, { useState, useEffect } from 'react';
import { Variant } from "../../../services/CommonInterfaces";
import "./SizePicker_WTB.scss";
import Input from "../input/Input";
import UserDataStorage from "../../../singletonStorage/userDataStorage";

interface SizePickerPropsInterface {
    variants: { [key: string]: Variant };
    qty?: Map<string, { qty: number, price: number, currency: string }>;
    onChange: (args: Map<string, { qty: number, price: number, currency: string }>) => void;
}

const SizePicker_WTB: React.FC<SizePickerPropsInterface> = (props) => {

    const [pickedSize, setPickedSize] = useState<Map<string, { qty: number, price: number, currency: string }>>(new Map());

    const [currency, setCurrency] = useState<string>();

    const userData = UserDataStorage.getInstance();

    useEffect(() => {
        if (props.qty) {
            setPickedSize(new Map(Object.entries(props.qty)));
        }
    }, [props.qty])

    useEffect(() => {
        let currency = userData.settingsStorage ? userData.settingsStorage!['currency'] : 'EUR';
        setCurrency(currency);
    }, [userData.settingsStorage]);

    const sizeHandler = (action: string, variantId: string, str?: string) => {
        let obj = {
            qty: 0,
            price: 0,
            currency: currency!
        };
        if (pickedSize.get(variantId)) {
            obj = pickedSize.get(variantId)!;
        }
        if (action === "input") {
            obj.qty = Number(str);
            setPickedSize((map) => { map.set(variantId, obj); return new Map(map) });
            props.onChange(pickedSize);
            return;
        }
        let selected: number = pickedSize.get(variantId)?.qty ?? 0;
        if (action === "add" && selected < 99) {
            obj.qty = selected + 1;
            setPickedSize((map) => { map.set(variantId, obj); return new Map(map) });
            props.onChange(pickedSize);
            return;
        }
        if (action === "remove" && selected > 0) {
            obj.qty = selected - 1;
            setPickedSize((map) => { map.set(variantId, obj); return new Map(map) });
            props.onChange(pickedSize);
            return;
        }
        if (action === "price") {
            obj.price = Number(Number(str?.replaceAll(',', '.')).toFixed(2));
            obj.currency = currency!;
            setPickedSize((map) => { map.set(variantId, obj); return new Map(map) });
            props.onChange(pickedSize);
        }
        if (action === "currency") {
            setCurrency(str);
            pickedSize.forEach((value) => {
                value.currency = str!;
            })
            setPickedSize((_pickedSize) => new Map(_pickedSize));
            props.onChange(pickedSize);
        }
    }


    return (
        <>
            <div className={"sizePickerWTB col-12 col-sm-7"}>
                <div className={"sizeLabels"}>
                    <div className={"row ms-1 me-1 d-flex align-content-center"}>
                        <div className={"col-1 mx-2 px-0 text-center"}>
                            <div className={"label"}>EU</div>
                        </div>
                        <div className={"col-1 mx-2 px-0 text-center"}>
                            <div className={"label"}>US</div>
                        </div>
                        <div className={"col-1 mx-2 px-0 text-center"}>
                            <div className={"label"}>UK</div>
                        </div>
                        <div className={"col-1 mx-2 px-0 text-center"}>
                            <div className={"label"}>CM</div>
                        </div>
                        <div className={"col-2 mx-1 text-center"}></div>
                        <div className={"col-3 px-3 ms-1 pe-0 me-0 text-center"}>
                            <div className={"label"}>PRICE</div>
                        </div>
                    </div>
                </div>
                <div className={"sizeList"}>
                    {[...Object.keys(props.variants).sort().map((v, i) => {
                        return <ValuePicker
                            key={i}
                            sizeHandler={(type, sizeId, str) => sizeHandler(type, sizeId, str)}
                            pickedSize={(pickedSize.get(props.variants[v].sizeId)?.qty ?? "0").toString()}
                            variants={props.variants} v={v}
                            currency={currency ?? 'EUR'}
                        />;
                    })]}
                </div>
            </div>
        </>
    );
}

export default SizePicker_WTB;

interface ValuePickerProps {
    pickedSize: string;
    variants: { [p: string]: Variant };
    v: string;
    sizeHandler: (type: string, sizeId: string, str?: string) => void;
    currency: string;
}

const ValuePicker: React.FC<ValuePickerProps> = ({ pickedSize, variants, v, sizeHandler, currency }) => {

    const [enableInput, setEnableInput] = useState(false);
    const [input, setInputValue] = useState(pickedSize);
    const [purchasePrice, setPurchasePrice] = useState<string>();

    const userData = UserDataStorage.getInstance();

    const setInput = (str: string) => {
        setInputValue(str);
        sizeHandler("input", variants[v].sizeId, str);
    }

    const setPrice = (str: string) => {
        setPurchasePrice(str);
        sizeHandler("price", variants[v].sizeId, str);
    }

    const setCurrency = (str: string) => {
        sizeHandler("currency", variants[v].sizeId, str);
    }

    useEffect(() => {
        setInputValue(pickedSize);
    }, [pickedSize])


    return (
        <>
            <div key={variants[v].sizeId} className={"row ms-1 me-1 d-flex align-content-center item"}>
                <div className={"col-1 mx-2 px-0 text-center"}>
                    <div className={"label"}>{variants[v].size.EU}</div>
                </div>
                <div className={"col-1 mx-2 px-0 text-center"}>
                    <div className={"label"}>{variants[v].size.US}</div>
                </div>
                <div className={"col-1 mx-2 px-0 text-center"}>
                    <div className={"label"}>{variants[v].size.UK}</div>
                </div>
                <div className={"col-1 mx-2 px-0 text-center"}>
                    <div className={"label"}>{variants[v].size.CM}</div>
                </div>
                <div className={"col-2 mx-2 px-0 text-center d-flex justify-content-evenly align-items-center counter"}>
                    <i className="fa-solid fa-minus cursor-pointer" onClick={() => { setEnableInput(false); sizeHandler("remove", variants[v].sizeId) }}></i>
                    {!enableInput && <span onClick={() => setEnableInput(true)} className={"font-weight-bolder"}>{input}</span>}
                    {enableInput && <input autoFocus className={"inputPicker"} value={input} onChange={(event) => { setInput(event.target.value) }} />}
                    <i className="fa-solid fa-plus cursor-pointer" onClick={() => { setEnableInput(false); sizeHandler("add", variants[v].sizeId) }}></i>
                </div>
                <div className={"col-2 mx-1 ms-3 text-center"}>
                    <Input customClass={"sizePickerPriceWTB"} customClassContainer={"sizePickerPriceContainerWTB"} id={"purPrice"}
                        type={"price"}
                        onChangeSelect={(str) => setCurrency(str)}
                        defaultValueSelect={currency}
                        defaultValue={purchasePrice}
                        valueSelect={userData.sessionStorage!['currencies']}
                        onChange={(str) => setPrice(str)}
                    />
                </div>
            </div>
        </>
    )
}