import React, { useCallback, useEffect, useMemo, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import './Listed.scss';
import Filters from "../../../common/filters/Filters";
import RowFilters from "../../../common/rowFilters/rowFilters";
import RowItem from "../../../common/rowItem/rowItem";
import Loader from "../../../common/loader/Loader";
import FiltersUtils, { FiltersInterface as SortFiltersInterface } from "../../../../utility/filtersUtils";
import PaginationFilters from '../../../common/paginationFilters/PaginationFilters';
import ListedListService from '../../../../services/listed/ListedListService';
import { PaginationFiltersInterface } from '../../../../services/CommonInterfaces';
import { ListedFilters } from '../../../../services/listed/ListedFilterInterfaces';
import { InventoryListInterface, InventoryResultInterface } from '../../../../services/InventoryInterfaces';
import { checkIfArrayAreEquals } from "../../../../utility/commonUtils";
import WarningModal from "../../../modals/WarningModal/WarningModal";
import LazyImage from "../../../common/lazyimage/LazyImage";


const Listed: React.FC = () => {

    //Service
    const listedService = useMemo(() => {
        let service = new ListedListService();
        service.MAX_ELEMENTS = FiltersUtils.initializeItemPerPage();
        return service;
    }, []);

    const [MAX_ELEMENTS, setMaxElements] = useState<number>(FiltersUtils.initializeItemPerPage());
    //const { t } = useTranslation('Shippings');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<ListedFilters>();
    const [sortFilters, setSortFilters] = useState<Array<SortFiltersInterface>>(FiltersUtils.initializeFilters('listed'));
    const [paginationFilters, setPagination] = useState<PaginationFiltersInterface>({ page: 0, count: undefined });
    const [list, setList] = useState<InventoryListInterface>();
    const [listItems, setListItems] = useState<Array<InventoryResultInterface>>([]);

    const [checkboxAll, setCheckboxAll] = useState<boolean>(false);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    const [selectedTasks, setSelectedTasks] = useState<Array<string>>([]);
    const [warningModal, setWarningModal] = useState<{ show: boolean, message: string, args: any, isLoading: boolean }>({ show: false, message: '', args: {}, isLoading: false });

    const [hideInfo, setHideInfo] = useState(false);

    const getList = useCallback(async (sortFilters?: SortFiltersInterface[], filters?: ListedFilters, paginationFilters?: PaginationFiltersInterface) => {
        setIsLoading(true);
        return await listedService.getProducts(sortFilters, filters, paginationFilters).then((_list) => {
            setSelectedTasks([...[]]);
            setList(_list);
            setPagination((prev) => { return { ...prev, count: _list.itemsCount } });
            setListItems(_list.results && _list.results !== null ? [..._list.results] : []);
            setIsLoading(false);
        });
    }, [listedService]);

    useEffect(() => {
        getList(sortFilters, filters, paginationFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getList, sortFilters, filters, paginationFilters.page]);

    useEffect(() => {
        setPagination((prev) => { return { ...prev, page: 0 } });
    }, [filters, MAX_ELEMENTS]);

    const refreshList = useCallback(async () => {
        await getList(sortFilters, filters, paginationFilters);
    }, [filters, getList, paginationFilters, sortFilters])

    const handleCheckbox = () => {
        setCheckboxAll(!checkboxAll);
        setTaskSelection(null, true);
    }

    const handleFilters = (filter: any) => {
        if (filter) {
            setFilters(filter);
            return;
        }
        setFilters(undefined);
    }

    const handlePaginationFilter = (pagFilter: number) => {
        if (pagFilter !== undefined) {
            setPagination((prev) => { return { ...prev, page: pagFilter } });
            return;
        }
    }

    const handleSortFilter = (filter?: string) => {
        if (!filter) {
            setSortFilters([...FiltersUtils.initializeFilters('listed')]);
            return;
        }
        if (filter === 'hideInfo') {
            setHideInfo((prev) => !prev);
            return;
        }
        if (sortFilters) {
            let filterToUpdate = sortFilters.find(e => e.name === filter);
            if (filterToUpdate) {
                (filterToUpdate.value = !filterToUpdate!.value);
                (filterToUpdate.lastSelect = true);
                sortFilters.forEach(e => {
                    if (e.name !== filterToUpdate!.name) {
                        e.value = false;
                        e.lastSelect = false;
                    }
                });
                localStorage.setItem('filtersListings', JSON.stringify(sortFilters));
                setSortFilters([...sortFilters]);
            }
            return;
        }
        setSortFilters([]);
    }

    const openModal = (bool?: boolean) => {
        setShowAddModal(bool || !showAddModal);
    }

    const deleteTask = (itemId?: string, confirmed?: boolean) => {
        if (!confirmed) {
            setWarningModal(
                {
                    message: (itemId || selectedTasks.length === 1) ? 'Are you sure you want to delete this item?' : ('Are you sure you want to delete ' + selectedTasks.length + '  items?'),
                    show: true,
                    args: { selectedItem: selectedTasks.length === 1 ? listItems.find(e => e.Id === (itemId || selectedTasks[0])) : null, params: itemId },
                    isLoading: false
                });
            return;
        }
        listedService.deleteListing(itemId ? [itemId] : selectedTasks).then(e => {
            setWarningModal((prev) => ({ show: false, message: prev.message, args: prev.args, isLoading: false }));
            setIsLoading(true);
            selectedTasks.length > 0 && setSelectedTasks([]);
            refreshList();
        })
    }


    const setItemPerPage = (pages: number) => {
        listedService.MAX_ELEMENTS = pages;
        localStorage.setItem('itemPerPage', pages.toString());
        setMaxElements(pages);
        setPagination((prev) => { return { ...prev, page: 0 } });
        refreshList();
    }

    const setTaskSelection = (v: any, all?: boolean) => {
        if (all) {
            setSelectedTasks((prev) => {
                if (checkIfArrayAreEquals(prev, listItems.map(e => e.Id))) {
                    return [];
                } else {
                    return [...listItems.map(e => e.Id)]
                }
            })
        } else {
            setSelectedTasks((prev) => {
                if (prev.includes(v.Id)) {
                    return [...prev.filter(e => e !== v.Id)]
                } else {
                    return [...prev, v.Id]
                }
            })
        }
    }

    const closeModal = (str: string, didChange?: boolean) => {
        switch (str) {
            case "warning":
                if (didChange) {
                    setWarningModal((prev) => ({ ...prev, isLoading: true }));
                    deleteTask(warningModal?.args?.selectedItem?.Id, true);
                } else {
                    setWarningModal((prev) => ({ show: false, message: prev.message, args: prev.args, isLoading: false }));
                }
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className='listed'>
                <Filters type={'listed'} openModal={() => openModal()} onChange={(args) => { handleFilters(args) }} showDeleteSelectedTasks={selectedTasks.length > 0} deleteSelectedTasks={() => deleteTask()} />
                <RowFilters hideInfo={hideInfo} filters={sortFilters} selected={listItems?.length > 0 ? checkIfArrayAreEquals(selectedTasks, listItems.map(e => e.Id)) : false} handleCheckbox={() => handleCheckbox()} handleFilterClick={(str) => handleSortFilter(str)} />
                {isLoading && <div className='listedContainer d-flex justify-content-center align-items-center w-100'>
                    {<Loader bootstrapLoader customStyle={{ width: '36px', height: '36px', marginTop: '50px' }} />}
                </div>}
                {!isLoading && listItems?.length > 0 && <div className='listedList'>
                    {listItems.map((v, i) =>
                        <RowItem hideInfo={hideInfo} selected={selectedTasks.includes(v.Id)} onSelect={() => { setTaskSelection(v) }} refresh={() => refreshList()} delete={() => deleteTask(v.Id, true)} key={i} filters={sortFilters} type="listings" item={v} />)
                    }
                </div>}
                {
                    !isLoading && !list?.results && <div className='listedContainer d-flex justify-content-center align-items-center w-100'>
                        {
                            <span className="color-white font-weight-bold listMessage"
                                onClick={() => openModal()}>
                                This list is empty, <span className="text-decoration-underline cursor-pointer">Add a Product from Inventory Section</span>
                            </span>
                        }
                    </div>
                }
                {!isLoading && <div className='divider' />}
                {!isLoading && <PaginationFilters count={paginationFilters.count} selected={paginationFilters.page!} itemPerPage={MAX_ELEMENTS} updateElements={(pages) => { setItemPerPage(pages); }} onChange={(res) => { handlePaginationFilter(res) }} />}
            </div>

            {<WarningModal isLoading={warningModal.isLoading} show={warningModal.show} onHide={(didChange?: boolean) => { closeModal("warning", didChange) }}>
                <div className="text-center">
                    <div>{warningModal.message}</div>
                    {warningModal.args.selectedItem &&
                        <div className="d-flex align-items-center justify-content-start mt-2 w-100">
                            <LazyImage customClass="image" src={warningModal.args.selectedItem?.item?.imgUrl} alt="Item" />
                            <div className="ps-1 w-100 d-flex flex-column align-items-start justify-content-center" style={{ fontWeight: 600 }}>
                                <div className={"w-100 text-center"}>{warningModal.args?.selectedItem?.item?.name}</div>
                                <div className={"w-100 text-center"}>{warningModal.args?.selectedItem?.item?.sku}</div>
                            </div>
                        </div>
                    }
                </div>
            </WarningModal>}
        </>
    );
}


export default Listed;