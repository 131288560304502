import React, { useState } from 'react';
import './Switch.scss';

interface SwitchProps {
    id: string;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
    customClass?: string;
    placeholder?: string;
    value?: boolean;
}

const Switch: React.FC<SwitchProps> = (props) => {

    const [checked, setChecked] = useState<boolean>(props.value || false);

    return (
        <>
            {/* <select
                    key={`${Math.floor((Math.random() * 1000))}-min`}
                    disabled={props.disabled}
                    className={`${props.customClass || ""} btn selectCommon`}
                    id={props.id}
                    value={props.selectedValue || ""}
                    placeholder={props.placeholder}
                    onChange={(event) => props.onChange(event.target.value)}
            >
                <option value="" disabled>{props.placeholder}</option>
                {props.value ? props.value.map((e,i)=>{
                    return <option key={e + i} value={e}>{e}</option>
                }) :  <option disabled key={'not'}>Empty</option>}
            </select> */}
            <div className={`${props.customClass || " "} form-check form-switch`}>
                <input disabled={props.disabled} className="form-check-input" type="checkbox" checked={checked} id="flexSwitchCheckDefault" onChange={(event)=>{event.currentTarget.blur(); setChecked(!checked); props.onChange(!checked);}}/>
                {props.placeholder && <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{paddingTop: 2}}>{props.placeholder}</label>}
            </div>
        </>
    );
}


export default Switch;
