import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from "firebase/messaging"; // Importa isSupported

const firebaseConfig = {
    apiKey: "AIzaSyBE_0zmuBi7wn2v6jxNGVkVL8KIKuvo0v0",
    authDomain: "stepupwebpush.firebaseapp.com",
    projectId: "stepupwebpush",
    storageBucket: "stepupwebpush.appspot.com",
    messagingSenderId: "222842538394",
    appId: "1:222842538394:web:e025df361ba19841279223",
    measurementId: "G-871LDWQ8ZP"
};

// Inizializza l'app Firebase
initializeApp(firebaseConfig);

let messaging;

const initializeMessaging = async () => {
  // Verifica che Firebase Messaging sia supportato dal browser
  if (
    await isSupported() && // isSupported() restituisce una promessa, quindi aspettiamo il risultato
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window
  ) {
    messaging = getMessaging();
  } else {
    console.log('Firebase Messaging o le notifiche non sono supportate su questo browser.');
  }
};

// Richiede il token solo se messaging è stato inizializzato correttamente
export const requestForToken = async () => {
  await initializeMessaging();

  if (messaging) {
    return await getToken(messaging, { vapidKey: 'BPCgIPB5v_T32AhRODPLUW8jeX2ggtnMyVVJ3jdMeSwveR354U0pWAlmCxL0C0b5i2hAk2n-8Ne61xmbEb_bRNw' })
      .then((currentToken) => {
        if (currentToken) {
          return currentToken;
        } else {
          console.log('Nessun token di registrazione disponibile. Richiedere il permesso per generare uno.');
          return false;
        }
      })
      .catch((err) => {
        console.log('Si è verificato un errore durante il recupero del token: ', err);
        return false;
      });
  } else {
    console.log('Firebase Messaging non è stato inizializzato.');
    return false;
  }
};
